import { PageHeader } from 'components/Text'
import React, {useState} from 'react'
import SelectCoin from '../../CreateAirdrop/Components/SelectCoin'
import LaunchAirdrop from '../../CreateAirdrop/Components/LaunchAirdrop'
import { InputField } from 'components/Forms/TextInput'
import { FullNavSegment } from 'components/Nav/NavSegment'
import SegmentComponent from 'components/Segments'
import SelectField from 'components/Forms/Select'



const Airdrop = () => {

    const [show, setShow] = useState(false)
    const [details, setDetails] = useState({
        token: '',
        numToken: '',
        numRecipients: '',
        title: '',
        type: ''
    })

    return (
        <div>
            <PageHeader
                title =  "Send tokens to your members"
                description = "Let's get started with some details about your reward campaign"
            />
            <Details 
                details = {details}
                setDetails = {setDetails}
            />
            <Conditions />
            <div class='mt-5 mb-5 d-flex justify-content-end'>
                <div class='btn  btn-soft-primary mr-3'>
                    Save as draft
                </div>
                <div class='btn  btn-primary' onClick={() => setShow(true)}>
                    Launch campaign
                </div>
            </div>
            <LaunchAirdrop 
                show = {show}
                setShow = {setShow}
            />
        </div>
    )
}

export default Airdrop


const Details = ({details, setDetails}) => {

    const [show, setShow] = useState(false)

    return (
    <div class="row mb-5">
        <div class="col-lg-4 mb-3 mb-lg-0">
            <h4>Enter campaign details</h4>
            <p>Let's get started with some details about your airdrop campaign</p>
        </div>
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body">
                    <InputField
                        value = {details.title}
                        label = "Campaign Title"
                        description="Brief description of your reward campaign"
                        setValue={(e) => setDetails({...details, title: e})}
                        margin ="mb-5"
                        labelClass="bold"
                        placeholder="eg, Weekly rewards"
                    />
                    <div className='form-group mb-5 mt-3'>
                        <label class='input-label bold'>Token to reward</label>
                        <p className='mt-n2 fs-sm'>Select a token you own from your connected wallet</p>
                        <input 
                            class='form-control' 
                            onClick={() => setShow(true)}
                            value = {details.token}
                        />
                    </div>
                    <SelectField 
                        value = {details.type}
                        label = "Type of claim"
                        description="Choose whether you want users to claim their tokens using a claim's page or a directly airdrop tokens to their wallet"
                        setValue={(e) => setDetails({...details, type: e})}
                        margin ="mb-5"
                        labelClass="bold"
                        placeholder="eg, 100"
                        options = {[{
                            value: '',
                            label: 'Please select'
                        }, {
                            value: 'claim',
                            label: 'Using unique claim page'
                        }, {
                            value: 'direct',
                            label: 'Directly airdrop tokens'
                        }]}
                    />
                    <InputField 
                        value = {details.numTokens}
                        label = "Number of tokens per claim"
                        description="Amount Of Tokens You'd Like To Send Per Recipient"
                        setValue={(e) => setDetails({...details, numTokens: e})}
                        margin ="mb-5"
                        labelClass="bold"
                        placeholder="eg, 100"
                    />
                    <InputField 
                        value = {details.numRecipients}
                        label = "Number of recipients"
                        description="Total number of recipients for this reward campaign"
                        setValue={(e) => setDetails({...details, numRecipients: e})}
                        margin ="mb-5"
                        labelClass="bold"
                        placeholder="eg, 100"
                    />
                </div>
            </div>
        </div>
        <SelectCoin 
            show = {show}
            setShow = {setShow}
            setToken = {e => setDetails(prev => ({...prev, token: e}))}
        />
    </div>
    )
}


const Conditions = () => {

    const [selected, setSelected] = useState('all')

    return (
    <div class="row">
        <div class="col-lg-4 mb-3 mb-lg-0">
            <h4>Set conditions for claiming reward</h4>
            <p>Gate your reward to specific token holders, NFT holders or whitelisted set of members</p>
        </div>
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body py-5">
                    <div class="alert alert-soft-primary mb-4" role="alert">
                        You can select a custom audience to receiev this aidrop. You can either choose by their wallet, member or activity attributes. 
                    </div>
                    <div class='d-flex w-100 '>
                        <FullNavSegment
                            navMap = {filtersNav}
                            selected = {selected}
                            setSelected = {setSelected}
                        />
                    </div>
                    {selected === 'custom' && <div class='mt-4'><SegmentComponent /></div>}
                    {selected === 'static' && <div class='mt-4'><Addresses /></div>}
                </div>
            </div>
        </div>
    </div>
    )
}


const Addresses = () => {

    const [value, setValue] = useState("")

    return (
        <div>
            <InputField 
                placeholder = ""
                label = "Write down addresses"
                type = "textarea"
                value = {value}
                setValue = {setValue}
                labelClass = ""
                textAreaHeigh = "5rem"
            />
        </div>
    )
}



const filtersNav = [{
    value: 'all',
    label: 'All members',
},{
    value: 'custom',
    label: 'Select from created segments',
}, {
    value: 'static',
    label: 'To addresses',
}]





