import React from 'react'
import { Redirect, Switch } from "react-router-dom";
import PublicRoute from 'components/Routes/PublicRoute';
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import AcceptInvitation from "pages/Auth/AcceptInvitation";
import ConfirmEmail from 'pages/Onboarding/ConfirmEmail';

const AdminPagesRoutes = () => {

    return (
        <>
            <Switch>
                <PublicRoute restricted = {true} exact path="/" component={Login}  />
                <PublicRoute restricted = {true} exact path="/register" component={Register}  />
                <PublicRoute restricted = {false} exact path="/forgot-password" component={ForgotPassword}  />
                <PublicRoute restricted = {false} exact path="/reset-password" component={ResetPassword}  />
                <PublicRoute restricted = {false} exact path="/accept-invite" component={AcceptInvitation}  />
                <PublicRoute restricted = {false} exact path="/confirm-email" component={ConfirmEmail}  />
                <Redirect from = "*" to = "/" />
            </Switch>
        
        </>
    );
}

export default AdminPagesRoutes;



