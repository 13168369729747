import Tooltip1 from 'components/Others/Tooltip'
import React from 'react'
import {homepageMetrics} from 'content/sampleData'

const KpiCard = () => {


    return (
        <>
        <div class="card card-body mb-3 mb-lg-5 mt-2">
            <div class="row gx-lg-4">
                <div class="col-sm-6 col-lg-3">
                    <SingleCard {...homepageMetrics[0]}/>  
                    <Border />
                </div>
                <div class="col-sm-6 col-lg-3 column-divider-sm">
                    <SingleCard {...homepageMetrics[1]}/>  
                    <Border />
                </div>
                <div class="col-sm-6 col-lg-3 column-divider-lg">
                    <SingleCard {...homepageMetrics[2]}/>  
                    <Border />
                </div>
                <div class="col-sm-6 col-lg-3 column-divider-sm">
                    <SingleCard {...homepageMetrics[3]}/>  
                    <Border />
                </div>
            </div>
        </div>
        </>
    )
}

export default KpiCard




const SingleCard = ({title, description, value, icon, trend, progress, help}) => {
    return (
        <div class="media">
            <div class="media-body">
                <h6 class="card-subtitle">
                    {title}
                    {help && <Tooltip1 tooltip= {help}/>}
                </h6>
                {(value !== null && !progress) && <>
                    <span class="card-title h1 mb-2">{value}</span>
                    <div class="d-flex align-items-center">
                        {(trend && typeof trend === 'number')  ? <>
                        <span class="d-block font-size-sm">{description}</span>
                        <span class={`badge badge-soft-${trend > 0 ? 'success' : 'danger'} ml-2`}>
                            <i class={`tio-trending-${trend > 0 ? 'up' : 'down'}`}></i> {trend}%
                        </span>
                        </>:<></>}
                        {trend === 'NOT_ENOUGH' ? <div class='mt-2'><div class='small'>Growth rate:</div>
                        <span class={`badge badge-soft-primary`}>
                            <i class='tio-mobile-data-low '></i> Not enough data
                        </span>
                        </div>:<></>}
                    </div>
                </>}
                {value === null && <div class='text-center py-4'>
                    <span class="spinner-border "></span>
                </div>}
                {(progress && value) && <Progress 
                    totalPositive = {progress.totalPositive}
                    totalNegative = {progress.totalNegative}
                />}
            </div>

            <span class="icon icon-sm icon-soft-secondary icon-circle ml-3">
                <i class={`tio-${icon}`}></i>
            </span>
        </div>
    )
}


const Border = () => <div class="d-lg-none"><hr /></div>



const Progress = ({totalPositive, totalNegative}) => {

    const data1 = parseInt(totalPositive/(totalPositive+totalNegative)*100)
    const data2 = parseInt(totalNegative/(totalPositive+totalNegative)*100)

    return (
        <div class='mt-4 mr-n4'>
            <div class="progress bg-white border  mb-2" style = {{height: '2rem'}}>
                <div class="progress-bar bg-soft-success text-success bold"  style={{width: data1+'%'}}>{data1}%</div>
                <div class="progress-bar bg-soft-danger text-danger bold"  style={{width: data2+"%"}}>{ data2}%</div>
            </div>
            <div class="d-flex justify-content-between small bold">
                <span>Positive</span>
                <span>Negative</span>
            </div>
        </div>
    )
}