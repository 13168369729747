import React, { useState } from 'react'
import Chart from 'components/Charts'
import NavSegment from 'components/Nav/NavSegment'
import Progress from 'components/Charts/Progress'
import Tooltip1 from 'components/Others/Tooltip'

const ReportChartComponent = ({tooltip, title, type, description, timeseriesData, metadata}) => {

    const [chartType, setChartType] = useState(type ? type : 'line')

  return (
        <div class={`card ${metadata && 'my-5'}`}>
            <div class="card-header">
                <div class="row align-items-center flex-grow-1">
                    <div class="col-sm mb-sm-0">
                        <h4 class="card-header-title mb-1">
                            {title} 
                            {tooltip && <Tooltip1 tooltip={tooltip}/>}
                        </h4>
                        <p class='mb-0'>{description}</p>
                    </div>
                    <div class="col-sm-auto">
                        {!type && <NavSegment 
                            navMap = {[{
                                label: '',
                                icon: 'chart-line-up',
                                value: 'line'
                            }, {
                                label: '',
                                icon: 'chart-bar-1',
                                value: 'bar'
                            }]}
                            selected = {chartType}
                            setSelected = {setChartType}
                        />}
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class={`col-md-${metadata ? 9 : 12} mb-5 mb-md-0`}>
                        <TimeSeries chartType = {chartType} timeseriesData = {timeseriesData}/>
                    </div>
                    {metadata && <div class="col-md-3 column-divider-md">
                        <DataPoints  metadata = {metadata}/>
                    </div>}
                </div>
            </div>
        </div>
  )
}

export default ReportChartComponent



export const DataPoints = ({metadata}) => {
    return (
        <div class='d-flex flex-column h-100'>
            <div class="row pl-3">
                <div class="col-sm-6 col-md-12">
                    <div class="d-flex justify-content-top flex-column" style={{minHeight: '10.5rem'}}>
                        <h6 class="card-subtitle">{metadata.title}</h6>
                        {(metadata && metadata.metric && metadata) && <>
                            {(metadata.metric.includes('%') && isFloat(metadata.metric)) && <h1>{metadata.metric} <small class='text-muted font-size-sm unbold'>{metadata.metricType}</small></h1>}
                            {(metadata.metric.includes('%')  && !isFloat(metadata.metric)) && <h1>Not enough data</h1>}
                            {(!metadata.metric.includes('%')) && <h1>{metadata.metric} <small class='text-muted font-size-sm unbold'>{metadata.metricType}</small></h1>}
                        </>}
                        {(metadata && metadata.data) && metadata.data.map(item => {
                            if(item.type === 'progress') return <Progress 
                                height="2rem"
                                {...item}
                            />
                            else if(item.type === 'line') return (<div class="">
                                <div class=" text-center">
                                    <Chart 
                                        height="12rem"
                                        labels = {item.xAxis}
                                        datasets =  {item.yAxis}
                                        stepsize = {item.stepsize}
                                        yprefix = {item.yPrefix}
                                        gradient = {true}
                                        type ={item.type}
                                        hideXAxis = {true}
                                    />
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
        </div>
            {metadata.helper && <div class='alert bg-soft-primary  mt-auto'>
                {metadata.helper}
            </div>}
        </div>
    )
}


export const TimeSeries = ({chartType, timeseriesData, hideLegends}) => {
    return (
        <div>
            <div class="row justify-content-center">
                {!hideLegends && timeseriesData.legends.map((item,index) => <div class="col-auto">
                    <span 
                        class={`legend-indicator`}
                        style = {{
                            backgroundColor: timeseriesData.customColors ? timeseriesData.customColors[index] : a[index]
                        }}
                    ></span> {item}
                </div>)}
            </div>
            {(timeseriesData && timeseriesData.xAxis.length > 0) ? <Chart 
                type ={chartType}
                labels = {timeseriesData.xAxis}
                datasets =  {timeseriesData.yAxis}
                stepsize = {timeseriesData.stepsize}
                yprefix = {timeseriesData.yPrefix}
                customColors = {timeseriesData.customColors}
                customGradientColors = {timeseriesData.customGradientColors}
                gradient = {true}
                height = "18rem"
                id = "chartjs-as32"
                {...timeseriesData}
            /> : <div class='d-flex align-items-center justify-content-center' style = {{minHeight: '18rem'}}>
                <span class='spinner-border'></span>
            </div>}
            
        </div>
    )
}

const a = ['#377dff', '#00c9db', '#e7eaf3']


function isFloat(value_){
    let value = value_.split('%').join('')
    if(!isFinite(value)) return false;
    if(typeof value === 'number') return true
    return typeof value === 'number' || isFinite(value) || Math.floor(value) === value;

    if(typeof n === 'number') return true
    return Number(n) === n && n % 1 !== 0;
}