
export const homepageMetrics = [
    {
        title: "New Users" ,
        description: "one week change", 
        value: '4,043', 
        icon: "group-junior", 
        trend: 12, 
    },
    {
        title: "Repeat Users" ,
        description: "one week change", 
        value: '1,004', 
        icon: "user-add", 
        trend: -12, 
    },
    {
        title: "Total Transactions" ,
        description: "one week change", 
        value: '34,500', 
        icon: "credit-card", 
        trend: 4, 
    },
    {
        title: "Total Value Locked" ,
        description: "one week change", 
        value: "$ 12M", 
        icon: "dollar", 
        trend: 9, 
    },
]


export const hompageDAUChart = {
    legends: ['Users'],
    yAxis: [
        [12, 34, 38, 45, 68, 76, 86, 97, 103, 108, 109, 109, 111 ]
    ],
    xAxis: [
        '1st Aug', '2nd Aug', '3rd Aug', '4th Aug', '5th Aug', '6th Aug', '7th Aug', '8th Aug', '9th Aug', '10th Aug', '11th Aug', '12th Aug'
    ],
    barWidth: 100,
}

export const hompageConversionChart = {
    legends: ['Conversion rate'],
    yAxis: [
        [100, 50, 15, 8 ]
    ],
    xAxis: [
        "Visit dApp", "Connect Wallet", "Wallet Transaction", "Stake Profits"
    ],
    barWidth: 100,
}