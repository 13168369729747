import React, {useContext, useEffect, useState} from 'react'
import {Table} from 'components/Table'
import { getSampleHeaders, getSampleMembers } from 'pages/Dashboard/Members/utilis/sampleData'


const DiscoverMembers = ({data}) => {


    return (
    <div class="card mb-3 mb-lg-5">
        <div class="card-header">
            <div class="row align-items-center flex-grow-1">
                <div class="col-sm mb-2 mb-sm-0">
                    <h4 class="card-header-title">Most Active Members </h4>
                    <p class='mb-0'>Identify your power users to engage with them and drive value</p>
                </div>
            </div>
        </div>
        <Table 
            headers = {getSampleHeaders('token')} 
            data = {getSampleMembers().docs.slice(0, 5)}
            isSort = {false}
            rowSelection
            selected={[]}
            setSelected={() => {}}
            size='lg'
        />
    </div>
  )
}

export default DiscoverMembers


