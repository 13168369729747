import Chart from 'components/Charts'
import { PageHeader } from 'components/Text'
import React, {useState} from 'react'
import filtersJson, {initialFilters} from '../Report/utilis/filters.js'
import ReportChartComponent from './components/ChartComponent.js'
import Filters from './components/Filters'
import HealthAtGlance from './components/HealthAtGlance'
import { hompageDAUChart, hompageConversionChart } from 'content/sampleData'

const AnalyzeWallet = () => {

	const [appliedFilters, setAppliedFilters] = useState(initialFilters);
	const [filters, setFilters] = useState(filtersJson);

  	return (
    	<div>
			<PageHeader 
				title = "Connected Wallet Analytics"
				description = "Analyze the on-chain behaviour of your connected wallets"
			/>
			<Filters 
                filters = {filters}
                appliedFilters = {appliedFilters}
                setAppliedFilters = {setAppliedFilters}
            />
			<HealthAtGlance />
			<div class='row mb-5'>
				<div class='col-md-7'>
					<ReportChartComponent
						title = "Wallet Labels"
						description = ""
						timeseriesData = {metrics.labels}
						// metadata = {metadata.labels}
					/> 
				</div>
				<div class='col-md-5'>
					<div class='card h-100'>
						<div class='card-body'>
							<TimeSeries 
								chartType = "bar" 
								timeseriesData = {hompageConversionChart}
								title= "Conversion flow for wallets"
								description = "8% of all users staked their profits "
							/>
						</div>
					</div>
				</div>
			</div>
			<ReportChartComponent
                title = "Common Protocols"
                description = ""
                timeseriesData = {metrics.commonProtocols}
				type= 'bar'
                metadata = {metadata.commonProtocols}
            />
			<ReportChartComponent
                title = "Common Assets"
                description = ""
                timeseriesData = {metrics.commonAssets}
				type= 'bar'
                metadata = {metadata.commonAssets}
            />
		</div>
  	)
}

export default AnalyzeWallet


const metrics = {
	labels: {
		legends: ['Count'],
		yAxis: [
			[1221, 412, 321, 234, 196, 164]
		],
		xAxis: [
			'Whale',
			'NFT newbiew',
			'Token Watcher',
			'Likely a bot',
			'Potential User',
			'Active Voter'
		],
		yPrefix: '',
		stepsize: 2,
		barWidth: 100
	},
	commonAssets: {
		legends: ['Count'],
		yAxis: [
			[1221, 412, 321, 234, 196, 164]
		],
		xAxis: [
			'$STACK',
			'$YFI',
			'$BAT',
			'$NMR',
			'$WBTC',
			'$LINK'
		],
		yPrefix: '',
		stepsize: 2,
		barWidth: 100
	},
	commonProtocols: {
		legends: ['Count'],
		yAxis: [
			[23010, 18341, 13954, 12121, 10234, 5983, 1241]
		],
		xAxis: [
			'Uniswap',
			"Axie Infinity",
			"Bancor",
			"Oasis",
			"Compound",
			"Ardana",
			"Synthetix"
		],
		yPrefix: '',
		stepsize: 2,
		barWidth: 100
	}
}

const metadata = {
	labels: {
		title: "Common Label",
        metric: "Whale " ,
        metricType: 'is most common label',
	},
	commonAssets: {
		title: "Common Asset",
        metric: "$STACK " ,
        metricType: 'is most common asset',
	},
	commonProtocols: {
		title: "Common Protocol",
        metric: "Uniswap " ,
        metricType: 'is most common used protocol',
	},

}



const TimeSeries = ({chartType, timeseriesData, title, description}) => {
    return (
        <div>
            <div class='mb-4'>
                <h4 className='mb-1'>{title}</h4>
                <p>{description}</p>
            </div>
            {(timeseriesData && timeseriesData.xAxis.length > 0) ? <Chart 
                type ={chartType}
                labels = {timeseriesData.xAxis}
                datasets =  {timeseriesData.yAxis}
                stepsize = {timeseriesData.stepsize}
                yprefix = {timeseriesData.yPrefix}
                customColors = {timeseriesData.customColors}
                customGradientColors = {timeseriesData.customGradientColors}
                gradient = {true}
                height = "19rem"
                id = "chartjs-as32"
                {...timeseriesData}
            /> : <div class='d-flex align-items-center justify-content-center' style = {{minHeight: '18rem'}}>
                <span class='spinner-border'></span>
            </div>}
            
        </div>
    )
}

const colors = ['#377dff', '#00c9db', '#e7eaf3']
