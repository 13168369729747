import React, {useState, useEffect, useContext} from 'react'
import {CheckBox} from 'components/Forms/Checkbox'
import {HrefButton, Button} from 'components/Forms/Buttons'
import {InputField, PasswordField} from 'components/Forms/TextInput'
import Text from 'components/Text'
import {Divider} from 'components/Others/Dividers'
import config from 'utilis/config'
import {Link} from 'react-router-dom'
import {loginUser} from 'services/user/auth'
import {loginSuccess} from 'store/actions/auth'
import {updateWorkplace} from 'store/actions/workplace'
import {Store} from 'store'

const Form = (props) => {

    const {dispatch} = useContext(Store)
    const [values, setValues] = useState({email: '', password: ''});
    const [errors, setErrors] = useState({email: false, password: false})
    const [loading, setLoading] = useState(false);

    

    const submitResponse = async () => {
        try{
            if(!validateData()) return;
            setLoading(true);

            const user = await loginUser({
                email: values.email.trim().toLowerCase(),
                password: values.password,
            });
            loginSuccess(user, dispatch)
            updateWorkplace(user.workplaceId, dispatch)
            props.history.push('/app')

            setLoading(false);
        } catch(err){
            console.log(err.response)
            setLoading(false);
            if(err.response && err.response.data && err.response.data.error === "INCORRECT_EMAIL"){
                setErrors({email: true})
            }
            if(err.response && err.response.data && err.response.data.error === "INCORRECT_PASSWORD"){
                setErrors({password: true})
            }
        }
    }


    const validateData = () => {
        const new_errors = {email: false, password: false, isTnc: false};
        if(values.email.length === 0) new_errors.email = true;
        if(values.password.length === 0) new_errors.password = true;
        setErrors(new_errors)
        if(Object.values(new_errors).includes(true)) return false;
        else return true;
    }


    const editValues = (name) => (value) => {
        setValues(prev => ({...prev, [name]: value }));
    };

    const editErrors = (name) => (value) => {
        setErrors(prev => ({...prev, [name]: value }));
    };

    useEffect(() => {
        setErrors({email: false, password: false, isTnc: false})
    }, [values])


    return(
        <form>
            <Text 
                title = "Sign in"
                // subTitle = {<>Don't have an account yet? <Link to = "/register">Sign up here</Link></>}
            /> 
            <HrefButton 
                className = 'mb-4'
                text = "Log in with Discord"
                img = "/assets/svg/brands/discord.svg"
                href = {`${config.serverLink}/api/v1/auth/discord`}
            />
            <Divider text = "OR"/>
            <InputField 
                name = "email"
                value = {values.email}
                error = {errors.email}
                setValue = {editValues('email')}
                setErrors = {editErrors('email')}
                placeholder = "bzz@bee.com"
                label = "Email"
                errorText = "Please enter a valid email address"
                size = "lg"
            />
            <PasswordField 
                name = "password"
                value = {values.password}
                error = {errors.password}
                setValue = {editValues('password')}
                setErrors = {editErrors('password')}
                label = "Password"
                errorText = "Please enter a valid password"
                sideLabel = {<Link tabindex={-1} class="input-label-secondary" to="/forgot-password">Forgot Password?</Link>}
                size = "lg"
            />
            <Button 
                text = "Sign in"
                loadingText = "Signing in..."
                submitResponse = {submitResponse}
                loading = {loading}
                size = "lg"
                block = {true}
                color = "primary"
            />
      </form>
    )
}

export default Form
