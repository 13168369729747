import React from 'react'

const NoAccess = () => {
  return (
    <div class='container py-6 mt-4'>
    	<div class="text-center">
        	<div class="h3">
          		<span class="badge badge-primary badge-pill ">
              		<i class="tio-hourglass-quarter"></i> Coming Soon
          		</span>
        	</div>
        	<div class="mb-4">
          		<h2>Sorry, we are not ready yet!</h2>
        	</div>
			<div class="w-lg-75 mx-lg-auto mb-5">
				<figure class="device-browser">
					<div class="device-browser-header">
						<div class="device-browser-header-btn-list">
						<span class="device-browser-header-btn-list-btn"></span>
						<span class="device-browser-header-btn-list-btn"></span>
						<span class="device-browser-header-btn-list-btn"></span>
						</div>
						<div class="device-browser-header-browser-bar">https://dashboard.intract.buzz/</div>
					</div>
					<div class="device-browser-frame" style = {{backgroundColor: '#fefff9'}}>
						<img class="device-browser-img" src="/assets/img/reward.png" alt="" style = {{maxHeight: '400px', objectFit: 'cover'}}/>
					</div>
				</figure>
				<p class='bold lead mt-6'>Please contact sambhav@intract.buzz to get you early invite</p>
				<p class='mt-3'>We will be launching our product to every web3 project soon! Please stay tuned</p>
			</div>
		</div>
	</div>
  )
}

export default NoAccess