import React, {useState, useContext, useEffect} from 'react'
import Tags from "components/Others/Tags"
import { AddTag } from 'components/Others/Tags'
import {addTagApi, deleteTagApi} from 'services/members'
import {getAllTagsApi} from 'services/workplace'
import { saveMemberTags } from 'store/actions/community'
import { Store } from 'store'
import NavSegment from 'components/Nav/NavSegment'
import Chart from 'components/Charts'
import {getSampleMembers} from '../../Members/utilis/sampleData'
import { Table } from 'components/Table'
import { DataPoints, TimeSeries } from 'pages/Dashboard/Report/components/ChartComponent'
import EnsSvg from '../../../../assets/svg/ens.svg'
import Address from 'components/Others/Address'
import topTokens from 'utilis/tokens/tokens.json'
import topNFTs from 'utilis/tokens/nfts.json'
import Avatar from 'components/Avatar'

export const DemoMemberSegments = ({userTags, setUserTags, memberId}) => {


    return (
        <div class='mb-3 mt-n2 row no-gutters align-items-center'>
            <div class= 'col-auto mb-1'>
                <i class='tio-label mr-2'></i>
            </div>
            <div class='col-5 mr-2 h4'>
                <Tags tags = {sampleSegments} />
            </div>
      </div>
    )
}




export const Identities = ({}) => {
    return (
        <div>
            <div class="js-sticky-block card mb-3 mb-lg-5" >
                <div class="card-header">
                    <h5 class="card-header-title">Profile</h5>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled list-unstyled-py-3 text-dark mb-3">
                        <li class="py-0"><small class="card-subtitle">On Chain Identities</small></li>
                        <li class='d-flex justify-content-between'>
                            <div>
                                <i class="mr-2 tio-user nav-icon"></i>Name
                            </div>
                            Sambhav Jain
                        </li>
                        <li class='d-flex justify-content-between'>
                            <div>
                                <i class="mr-2 tio-document-text nav-icon"></i>ENS
                            </div>
                            sambhavjain.eth
                        </li>
                        <li class='d-flex justify-content-between'>
                            <div>
                                <i class="mr-2 tio-wallet nav-icon"></i>Polygon Wallet
                            </div>
                            <Address size="sm" address="0x0250cd163a13b41030bc20d96868d93acdf51cf8"/>
                        </li>
                        <li class="pt-2 pb-0 mt-3"><small class="card-subtitle">Off Chain Identities</small></li>
                        <li class='d-flex justify-content-between'>
                            <div>
                                <i class="mr-2 tio-email nav-icon"></i>Email
                            </div>
                            sambhavj10@gmail.com
                        </li>
                        <li class='d-flex justify-content-between'>
                            <div>
                                <img src= "/assets/svg/brands/discord.svg" height="17" class='mr-2' style = {{filter: 'grayscale(1)'}}/>
                                {/* <i class="mr-2 tio-discord nav-icon"></i> */}
                                Discord
                            </div>
                            sambhavjain#1242
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}



export const WalletOverview = ({}) => {

    const [selected, setSelected] = useState('overview')
    return (
        <div class='mt-3'>
            <div class='card mb-4 mt-3'>
                <div class='card-header py-2 align-items-center'>
                    <h4 className='mb-0'>Assets</h4>
                    <NavSegment 
                        navMap={[{
                            label: 'Overview',
                            value: 'overview'
                        }, {
                            label: 'Tokens',
                            value: 'tokens'
                        },{
                            label: 'NFTs',
                            value: 'nfts'
                        }]}
                        selected={selected}
                        setSelected={setSelected}
                    />
                </div>
                {selected === 'overview' && <WalletAnalysis />}
                {selected === 'tokens' && <WalletTokens />}
                {selected === 'nfts' && <WalletNFTs />}
            </div>
        </div>
    )
}


const WalletAnalysis = ({}) => {


    return (
        <div class=''>
            <div class='row no-gutters'>
                <div class='col pl-1 py-1'>
                    <div class="card-body bg-white ">
                        <div className='row'>
                            <div class='col-6 border-left'>
                                <h6 class='text-muted mb-1'>Total balance</h6 >
                                <div class='h1 text-dark'>$1,920</div>
                            </div>
                            <div class='col-6 border-left'>
                                <h6 class='text-muted mb-1'>Life time value</h6 >
                                <div class='h1 text-dark'>$921 </div>
                            </div>
                        </div>
                        <div class="list-group list-group-flush list-group-no-gutters mt-4">
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        Top protocols:
                                    </div>
                                    <div class="col-auto">
                                        {getSampleMembers().docs[0].topTokens.label}
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        Top token holding:
                                    </div>
                                    <div class="col-auto">
                                        {getSampleMembers().docs[0].topTokens.label}
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        Blue chip NFTs:
                                    </div>
                                    <div class="col-auto">
                                        {getSampleMembers().docs[0].topTokens.label}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-5 p-3 '>
                    <div class="card-body rounded bg-light h-100">
                        <h4 class='text-center mb-4'>Source of funds</h4>
                        <Chart 
                            type='doughnut'
                            datasets = {[[12, 88]]}
                            labels = {['Tokens', 'NFTs']}
                            stepsize = {10}
                            yprefix = " "
                            height = "10rem"
                            id = "chartjs-7"
                        />
                        <div class="row justify-content-center mt-4 ">
                            {['Coinbase', 'Binance'].map((item,index) => <div class="col-auto">
                                <span 
                                    class={`legend-indicator`}
                                    style = {{
                                        backgroundColor: colors[index]
                                    }}
                                ></span> {item}
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WalletTokens = ({}) => {
    return (
        <div>
            <Table 
                data = {topTokens.slice(0, 10).map(item => ({
                    name: {
                        label: <Avatar 
                            name = {item.name}
                            avatar = {item.logoURI}
                            size = "sm"
                        />,
                        value: item.value
                    },
                    symbol: item.symbol,
                    _balance: Math.floor(Math.random() * 1000),
                    _address: {
                        value: item.address,
                        label: <Address  address={item.address}/>
                    }
                }))}
                headers = {tokenHeaders}
            />
        </div>
    )
}

const WalletNFTs = ({}) => {
    return (
        <div>
            <Table 
                data = {topNFTs.slice(0, 10).map(item => ({
                    name: {
                        label: <Avatar 
                            name = {item.name}
                            avatar = {item.logoURI}
                            size = "sm"
                        />,
                        value: item.value
                    },
                    _tokenUri: {
                        label: <a target="_blank" href = {item.extensions.ogImage}><i class='tio-link'></i></a>,
                        value: 'tokenUri'
                    },
                    _balance: Math.floor(Math.random() * 1000),
                    _address: {
                        value: item.address,
                        label: <Address  address={item.address}/>
                    }
                }))}
                headers = {nftHeaders}
            />
        </div>
    )
}



export const WalletActivity = ({}) => {
    return (
        <>
        <div class="card mb-3 mb-lg-5">
            <div class="card-header">
                <h5 class="card-header-title">Activity</h5>
                <div class="hs-unfold">
                    <a class="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle" >
                        <i class="tio-more-vertical"></i>
                    </a>
                </div>
            </div>
            <div class="card-body card-body-height" style={{height: "20rem"}}>
                <ul class="step step-icon-xs">
                    {walletActivity.map(item => <li class="step-item">
                        <div class="step-content-wrapper pb-3">
                            <span class="step-icon step-icon-pseudo step-icon-soft-dark"></span>
                            <div class="step-content">
                                <h5 class="mb-1">
                                    <a class="text-dark" href="#">{item.name}</a>
                                </h5>
                                <p class="font-size-sm mb-1"> {item.description} </p>
                                <span class="badge badge-soft-success badge-pill "><span class="legend-indicator bg-success mr-1"></span>Completed</span>
                                <small class="text-muted ml-2 ">
                                    {item.time}
                                </small>
                            </div>
                        </div>
                    </li>)}
                </ul>
            </div>
            <div class="card-footer py-2">
                <a class="btn btn-sm btn-ghost-secondary collapsed" data-toggle="collapse" href="#collapseActivitySection" role="button" aria-expanded="false" aria-controls="collapseActivitySection">
                    <span class="btn-toggle-default"><i class="tio-chevron-down mr-1"></i> View more</span>
                    <span class="btn-toggle-toggled"><i class="tio-chevron-up mr-1"></i> View less</span>
                </a>
            </div>
        </div>
        <TransactionChart />
        </>
    )
}


export const TransactionChart = ({}) => {

    const [chartType, setChartType] = useState("all")

    return (
        <div class="card mb-3 mb-lg-5">
            <div class="card-header">
                <div class="row align-items-center flex-grow-1">
                    <div class="col-sm mb-sm-0">
                        <h4 class="card-header-title mb-1">
                            Transactions
                        </h4>
                        <p class='mb-0'></p>
                    </div>
                    <div class="col-sm-auto">
                        <NavSegment 
                            navMap = {[{
                                label: 'All',
                                value: 'all'
                            }, {
                                label: 'My Smart Contracts',
                                value: 'mine'
                            }]}
                            selected = {chartType}
                            setSelected = {setChartType}
                        />
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-sm-6 col-lg">
                        <div class="media align-items-center">
                            <i class="tio-swap-horizontal tio-xl text-primary mr-3"></i>
                            <div class="media-body">
                                <span class="d-block font-size-sm">Total Transactions</span>
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0">{chartType === 'all' ? '4,413' : '2,423'}</h3>
                                    <span class="badge badge-soft-success ml-2">
                                        <i class="tio-trending-up"></i> {chartType === 'all' ? '12.4%' : '5.6%'}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-none">
                            <hr />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg column-divider-lg">
                        <div class="media align-items-center">
                        <i class="tio-dollar tio-xl text-primary mr-3"></i>
                            <div class="media-body">
                                <span class="d-block font-size-sm">Total Txn Value</span>
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0">{chartType === 'all' ? '$8,413' : '$1,513'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TimeSeries chartType="line" timeseriesData = {timeseriesData} hideLegends = {true}/>
            </div>
        </div>
    )
}







const sampleSegments = [{
    color: 'primary',
    label: "Crypto Whale"
}, {
    color: 'success',
    label: "Uniswap User"
}, {
    color: 'warning',
    label: "High activity"
},{
    color: 'info',
    label: "Token holder"
}]


const colors = ['#377dff', '#00c9db', '#e7eaf3']




const tokenHeaders = [{
    label: 'name',
    value: 'name',
}, {
    label: 'symbol',
    value: 'symbol',
}, {
    label: 'balance',
    value: '_balance',
}, {
    label: 'address',
    value: '_address',
}]


const nftHeaders = [{
    label: 'name',
    value: 'name',
}, {
    label: 'address',
    value: '_address',
}, {
    label: 'Url',
    value: '_tokenUri',
}, ]


const sampleTokens = Array(5).fill().map(item => ({
    name: 'Token',
    symbol: 'TKN',
    _balance: '$1,920',
    _address: '0x0'
}))

const sampleNfts = Array(5).fill().map(item => ({
    name: 'Token',
    _tokenId: '0x0',
    _balance: '$1,920',
    _tokenUri: 'https://www...'
}))

const timeseriesData = {
    legends: ['Transactions'],
    yAxis: [
        [12, 14, 12, 534, 12, 45, 12]
    ],
    xAxis: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'
    ],
    barWidth: 100,
}

const metadata = {
    title: 'Total number of transactions', 
    metric: "12,124",
    metricType: 'transactions', 
}


const walletActivity = [{
    name: 'Recieved Airdrop',
    description: '1000 $INTR for weekly quests campaign',
    time: 'Today',
},{
    name: 'Purchased ENS NFT',
    description: '0.1 $ETH for sambhavjain.eth',
    time: 'Yesterday',
},{
    name: 'Paid ETH',
    description: '0.1 $ETH for sambhavjain.eth',
    time: 'Yesterday',
},{
    name: 'Staked MATIC',
    description: '100 $MATIC on Polygon Staking',
    time: '15 August 2022',
}]