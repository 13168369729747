import React, {useCallback, useMemo} from 'react'
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    Handle, 
    Position
} from 'react-flow-renderer';



const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);


const CampaignFlow = ({templateId}) => {

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes(templateId));
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges(templateId));
    const nodeTypes = useMemo(() => ({ textUpdater: TextUpdaterNode }), []);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

    return (
        <div class="flow-canvas">
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitViewOptions={{ padding: 0.17 }}
                onInit={onInit}
                fitView
                nodeTypes = {nodeTypes}
                attributionPosition="top-right"
            >
                <Background color="#aaa" gap={16} />
            </ReactFlow>
        </div>
    )
}

export default CampaignFlow





const initialNodes = (templateId) => {
    const template = templates[templateId];
    if(!template) return []
    return template.map((item, index) => ({
        id: (index+1).toString(),
        type: 'textUpdater',
        data: {
            type: item.type,
            label: item.name,
            description: item.description,
            filters: item.filters,
            isTarget: index === 0 ? false : true,
            isSource: index === template.length ? false : true
        },
        position: {
            x: 0,
            y: 0 + index * 150
        },
        sourcePosition: 'right'
    }))
}


const initialEdges = (templateId) => {
    const template = templates[templateId];
    if(!template) return []
    else return Array(template.length - 1).fill(null).map((item, index) => ({
        id: `e${index}-${index+1}`,
        source: (index+1).toString(),
        target: (index+2).toString(),
        animated: true,
    }))
}







function TextUpdaterNode({ data }) {
    
    const onChange = useCallback((evt) => {
      console.log(evt.target.value);
    }, []);

    return (
        <div>
            {data.isTarget && <Handle type="target" position={Position.Top} style={{zIndex: '101212'}}/>}
            <div class={`placed-component trigger ${data.type}`}>
                <div class={`placed-component-body ${data.type === 'delay' && "align-items-center"}`}>
                    <div class="placed-component-icon-container">
                        <div class="placed-component-icon-background">
                            <i class={`tio-${data.type === 'trigger' ? 'clock' : (data.type === 'action' ? 'email' : 'flash')}`}></i>
                        </div>
                    </div>
                    <div class="placed-component-content ">
                        {data.type !== 'delay' && <div class="placed-component-header">
                            <div class="placed-component-title mb-1">
                                <span>{data.label}</span>
                            </div>
                        </div>}
                        <div class="placed-component-main">
                            {data.description}
                        </div>
                    </div>
                </div>
                {data.filters && <div class="placed-component-footer">
                    <div class="card-status-switcher-wrapper">
                        Flow conditions ({data.filters.length})
                    </div>
                </div>}
            </div>
            {data.isSource && <Handle type="source" position={Position.Bottom} style={{zIndex: '101212'}}/>}
        </div>
    );
  }

  



const templates = {
    'onboarding': [{
        type: 'logic',
        name: 'Trigger',
        description: 'When member holds project token',
        filters: [{
            description: 'When member total transaction value  > 1000 USD',
        }, {
            description: 'Member last active <30 days > ago'
        }]
    }, {
        type: 'delay',
        description: 'Wait 4 hours'
    }, {
        type: 'action',
        name: 'Send EPNS',
        description: 'Hi {firstName}, our product Intract delivers an APR of 12 % continuously...'
    },  {
        type: 'delay',
        description: 'Wait 2 days'
    }, {
        type: 'action',
        name: 'Send Email',
        description: 'Hi {firstName}, our product Intract delivers an APR of 12 % continuously...'
    }],
    'reactivation': [{
        type: 'logic',
        name: 'When members get churned',
        filters: [{
            description: 'When member total transaction value  > 1000 USD',
        }, {
            description: 'Member last active <30 days > ago'
        }]
    }, {
        type: 'action',
        name: 'Send Email',
        description: 'Hi Aaron, It has been a while since we last sa...'
    }]
}