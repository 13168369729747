import React, { useEffect } from 'react'
import Header from './components/Header'
import NodesConfig from './components/NodesConfig'
import CampaignFlow from './components/CampaignFlow'


const Journey = (props) => {

    useEffect(() => {
        console.log(props.match.params.id)
    }, [props.match.params.id])

    return (
        <div class='flows-body'>
            <Header />
            <div class='flows-main' style = {{marginTop: '60px'}}>
                <NodesConfig />
                <CampaignFlow templateId={"onboarding"}/>
            </div>
        </div>
    )
}

export default Journey