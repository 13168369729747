import React, {useState, useEffect, useContext} from 'react'
import HorizontalNav from 'components/Nav/HorizontalNav'
import Notes from './components/Notes'
import Activity from './components/Activity'
import Details from './components/Details'
import KPIsCard from './components/KPIsCard'
import MemberTags from './components/MemberTags'
import MemberRoles from './components/MemberRoles'
import moment from 'moment'
import { OverallActivityChart, SentimentChart } from './components/Charts'
import { ProfileCover, ProfileHeader } from './components/ProfileHeader'
import { Store } from 'store';
import { getMemberApi, getDiscordMemberMessagesApi, getDiscordMemberTsData } from 'services/members'
import { getAvatar } from 'utilis/avatars'
import formatTsData from './utilis/formatter'
// import WalletActivity from './components/WalletActivity'
import {DemoMemberSegments, WalletOverview, WalletActivity, Identities} from './components/DemoOnChain'
import {OffChainActivity, OffChainOverview} from './components/DemoOffChain'


const Member = (props) => {

    const {state, dispatch} = useContext(Store);
    const [selectedTab, setSelectedTab] = useState('wallet');
    const [member, setMember] = useState();
    const [activities, setActivities] = useState([]);
    const [tsData, setTsData] = useState({messages: null, sentiment: null});
    const [activityPage, setActivityPage] = useState(1)
    const [activitiesLoaded, setActivitiesLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingActivities, setLoadingActivities] = useState(false);
    const [discordProfile, setDiscordProfile] = useState();
    const [web3Profile, setWeb3Profile] = useState({})

    useEffect(() => {
        // fetchMember(props.match.params.id);
        // setActivities([])
        // setDiscordProfile()
        // setWeb3Profile({})
        // setTsData({messages: null, sentiment: null})
        // setActivitiesLoaded(false)
    }, [props.match.params.id])
    

    const fetchMember = async (id) => {
        try {
            const fetchedMember = await getMemberApi(id);
            if(fetchedMember.sources.find(item => item.type === 'DISCORD')) {
                let discordProfile_ = fetchedMember.sources.find(item => item.type === 'DISCORD');
                setDiscordProfile({
                    ...discordProfile_.discordMetadata,
                    roleIds: discordProfile_.discordMetadata.roles,
                })
                fetchMemberTsData(discordProfile_.discordMetadata.memberId)
            } else {
                setSelectedTab('wallet')
            }
            if(fetchedMember.sources.find(item => item.type === 'WEB3')) {
                let web3Profiles_ = fetchedMember.sources.filter(item => item.type === 'WEB3');
                setWeb3Profile(web3Profiles_.map(item => item.web3Metadata))
                // fetchMemberTsData(web3Profile_.memberId)
            }
            formatMember(fetchedMember)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        // if(discordProfile) fetchMemberActivities(discordProfile.memberId)
    }, [activityPage, discordProfile])


    const fetchMemberActivities = async (id) => {
        try {
            const urlParams = new URLSearchParams();
            urlParams.append('page', activityPage)
            urlParams.append('limit', 10)
            setLoadingActivities(true)
            const fetchedActivities = await getDiscordMemberMessagesApi(id, urlParams);
            setLoadingActivities(false)
            if(!fetchedActivities.hasNextPage) setActivitiesLoaded(true)
            formatActivities(fetchedActivities)
        } catch (error) {
            setLoadingActivities(false)
            console.log(error)
        }
    }


    const fetchMemberTsData = async (id) => {
        try {
            const fetchedTsData = await getDiscordMemberTsData(id);
            setTsData(formatTsData(fetchedTsData))
        } catch (err) {
            console.log(err)
        }
    }


    const formatMember = (fetchedMember) => {
        const data = {
            ...fetchedMember,
            avatar: fetchedMember.avatar.includes('null') ? '' : fetchedMember.avatar,
            organization: fetchedMember.adminInputs.organization ? fetchedMember.adminInputs.organization : '',
            joinDate: moment().format("Do MMM YY"),
            // joinDate: moment().format("Do MMM YY"),
            tags: fetchedMember.adminInputs.tags.map(item => ({value: item.tagId, label: item.label, color: item.color})),
            notes: fetchedMember.adminInputs.notes,
        }
        setMember(data)
    }


    const formatActivities = (activities) => {
        setActivities(prev => ([...prev, ...activities.activities.map(item => ({
            ...item,
            title: '#' + item.channel[0].name,
            icon: <i class='tio-message'></i>,
            description: item.content,
            time: moment((item.timestamp)).fromNow(),
            tags: item.metrics.sentiment ? [{value: item.metrics.sentiment, label: item.metrics.sentiment + ' sentiment', color: item.metrics.sentiment === 'positive' ? 'success' : 'danger'}] : []
        }))]))
    }

    // if(!member) return null;


    return (
        <div class = 'row justify-content-lg-center'>
            <div class= 'col-lg-11'>
                <ProfileCover />
                <ProfileHeader 
                    member = {member}
                    setMember = {setMember}
                />
                <DemoMemberSegments />
                {/* <MemberTags 
                    userTags = {member.tags}
                    setUserTags = {tags => (setMember({...member, tags}))}
                    memberId = {member._id}
                /> */}
                <div class = 'row mt-0 no-gutters'>
                    <div class = 'col'>
                        <HorizontalNav
                            navItems={navItems}
                            selected = {selectedTab}
                            setSelected = {setSelectedTab}
                        />
                        {selectedTab === 'wallet' && <WalletOverview />}
                        {selectedTab === 'wallet' && <WalletActivity />}
                        {selectedTab === 'activity' && <OffChainOverview />}
                        {selectedTab === 'activity' && <OffChainActivity />}
                        {/* {selectedTab === 'activity' && <Activity 
                            activities = {activities}
                            setActivityPage = {setActivityPage}
                            activitiesLoaded = {activitiesLoaded}
                            loading = {loadingActivities}
                        />}
                        {selectedTab === 'wallet' && <DemoWalletOverview 
                            web3Profiles = {web3Profile}
                        />} */}
                        {/* {selectedTab === 'notes' && <Notes 
                            notes = {member.notes}
                            memberId = {member._id}
                            member = {member}
                            setMember = {setMember}
                        />}
                        {selectedTab === 'details' && <Details 
                            discordProfile = {discordProfile}
                        />} */}
                    </div>
                    <div class = 'col ml-6' style = {{maxWidth: '30%'}}>
                        <Identities />
                        {/* {discordProfile && <MemberRoles 
                            userRoles = {discordProfile.roles}
                            setUserRoles = {roles => (setDiscordProfile({...member, roles}))}
                            memberId = {discordProfile.memberId}
                            member = {discordProfile}
                            setMember = {(d) => setDiscordProfile(d)}
                        />}
                        {discordProfile && <KPIsCard 
                            replies = {discordProfile.metrics.replies}
                            messages = {discordProfile.metrics.messages}
                            lastActive = {moment(discordProfile.updatedAt).format('Do MMM YYYY')}
                        />}
                        {discordProfile && <OverallActivityChart 
                            data = {tsData.messages}
                        />}
                        {discordProfile && <SentimentChart 
                            data = {tsData.sentiment}
                        />} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Member





const navItems = [
    {value: 'wallet', label: 'Wallet Activity'},
    {value: 'activity', label: 'Off chain Activity'},
    {value: 'notes', label: 'Notes'},
    {value: 'details', label: 'Details'},
]



