import React from 'react'

const NodesConfig = () => {
  return (
    <ul className='flow-action-panel'>
        <li class="mb-2">
            <h4>Actions</h4>
        </li>
        {actions.map(item => <li class="mb-2">
            <div class='btn btn-white btn-block text-left'>
                <i class={`${item.icon} mr-3 fs-lg`}></i>
                {item.name}
            </div>
        </li>)}
        <li class="mb-2 mt-4">
            <h4>Timing</h4>
        </li>
        {timings.map(item => <li class="mb-2">
            <div class='btn btn-white btn-block text-left'>
                <i class={`${item.icon} mr-3 fs-lg`}></i>
                {item.name}
            </div>
        </li>)}
        <li class="mb-2 mt-4">
            <h4>Logic</h4>
        </li>
        {logics.map(item => <li class="mb-2">
            <div class='btn btn-white btn-block text-left'>
                <i class={`${item.icon} mr-3 fs-lg`}></i>
                {item.name}
            </div>
        </li>)}
    </ul>
  )
}

export default NodesConfig



const actions = [
    {
        name: 'Email',
        icon: 'tio-email'
    },
    {
        name: 'SMS',
        icon: 'tio-sms'
    },
    {
        name: 'ERC721 Tokens',
        icon: 'tio-coin'
    },
    {
        name: 'ERC20 Tokens',
        icon: 'tio-comment-image'
    },
    {
        name: 'Soul Bounds Tokens',
        icon: 'tio-email'
    },
]

const timings = [
    {
        name: 'Time delay',
        icon: 'tio-clock'
    },
]

const logics = [
    {
        name: 'Conditional Split',
        icon: 'tio-category'
    },
    {
        name: 'Trigger Split',
        icon: 'tio-node-multiple'
    },
]


// 3. Appreciate your Contributors
//     1. When member has held NFT <address> for at least <15> days
//     2. AND
//     3. member has voted  at least <5> times  in the last <300> days
//     4. Send Airdrop
// 4. Nudge Staking behavior
//     1. When member holds token <address>
//     2. AND
//     3. has staked less than <5 %> of it
//     4. Send EPNS Push
//     5. [Hi Aaron, our product Intract delivers an APR of 12 % continuously. Heaad to [intract.buzz](http://intract.buzz) and stake your tokens]

