import Chart from "components/Charts"
import Tags from "components/Others/Tags"
import { Button } from "components/Forms/Buttons";
import ActivitySteps from "components/Steps";
import { useState } from "react";
import moment from 'moment'
import NavSegment from "components/Nav/NavSegment";
import { TimeSeries } from "pages/Dashboard/Report/components/ChartComponent";


export const OffChainOverview = ({}) => {
    return (
        <div class='card mt-3'>
            <div class='card-header'>
            <h4 class="card-title">Activities</h4>
            </div>
            <div>
            <div class='row no-gutters'>
                <div class='col pl-3 py-3'>
                    <div class="card-body bg-white ">
                        <div className='row'>
                            <div class='col-6 '>
                                <h6 class='text-muted mb-1'>Engagement Percentile</h6 >
                                <div class='h1 text-dark'>Top 1%</div>
                            </div>
                            <div class='col-6 border-left'>
                                <h6 class='text-muted mb-1'>Total Reach</h6 >
                                <div class='h1 text-dark'>10K</div>
                            </div>
                        </div>
                        <div class="list-group list-group-flush list-group-no-gutters mt-4">
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <i class="tio-message"></i> Total messages:
                                    </div>
                                    <div class="col-auto">
                                        <span class='badge badge-success'>
                                         1,121 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <i class="tio-gym"></i> Contributor score:
                                    </div>
                                    <div class="col-auto">
                                        <span class='badge badge-success'>
                                            Top 1%
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <i class="tio-reply"></i> Responsiveness:
                                    </div>
                                    <div class="col-auto">
                                        <span class='badge badge-success'>
                                         70% 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <Tags tags = {discordRoles}/>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class='col-5 p-3 '>
                    <div class="card-body rounded bg-light h-100">
                        <h4 class='text-center mb-4'>Sentiment</h4>
                        <Chart
                            type='doughnut'
                            datasets = {[[12, 88]]}
                            labels = {['Positive', 'Negative']}
                            stepsize = {10}
                            customColors= {colors}
                            yprefix = " "
                            height = "10rem"
                            id = "chartjs-7"
                        />
                        <div class="row justify-content-center mt-4 ">
                            {['Positive', 'Negative'].map((item,index) => <div class="col-auto">
                                <span 
                                    class={`legend-indicator`}
                                    style = {{
                                        backgroundColor: colors[index]
                                    }}
                                ></span> {item}
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}




export const OffChainActivity = ({}) => {

    const [loading, setLoading] = useState(false);

    return (
        <>
            <div class="card mt-5">
                <div class="card-header">
                    <h4 class="card-title">Activities</h4>
                </div>
                <div class="card-body">
                    <ActivitySteps  data = {messages}/>
                    <Button 
                        loading = {loading}
                        submitResponse = {() => {}} 
                        text = "Load more activities" 
                        loadingText = "Loading..."
                        color = "white"
                        block = {true}
                        icon = "tio-refresh"
                        disabled = {false}
                    />
                </div>
            </div>
            <ActivityChart />
        </>
    )
} 



export const ActivityChart = ({}) => {

    const [chartType, setChartType] = useState("discord")

    return (
        <div class="card mt-5 mb-3 mb-lg-5">
            <div class="card-header">
                <div class="row align-items-center flex-grow-1">
                    <div class="col-sm mb-sm-0">
                        <h4 class="card-header-title mb-1">
                            Off Chain Activity
                        </h4>
                        <p class='mb-0'></p>
                    </div>
                    <div class="col-sm-auto">
                        <NavSegment 
                            navMap = {[{
                                label: 'Discord',
                                // icon: 'chart-line-up',
                                value: 'discord'
                            }, {
                                label: 'Twitter',
                                // icon: 'chart-bar-1',
                                value: 'twitter'
                            }]}
                            selected = {chartType}
                            setSelected = {setChartType}
                        />
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-sm-6 col-lg">
                        <div class="media align-items-center">
                            <i class="tio-chat-outlined tio-xl text-primary mr-3"></i>
                            <div class="media-body">
                                <span class="d-block font-size-sm">Total messages</span>
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0">34,413</h3>
                                    <span class="badge badge-soft-success ml-2">
                                        <i class="tio-trending-up"></i> 12.5%
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-none">
                            <hr />
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg column-divider-lg">
                        <div class="media align-items-center">
                        <i class="tio-twitter tio-xl text-primary mr-3"></i>
                            <div class="media-body">
                                <span class="d-block font-size-sm">Total tweets</span>
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0">121</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TimeSeries chartType="line" timeseriesData = {timeseriesData} hideLegends = {true}/>
            </div>
        </div>
    )
}





const colors= ['#00c9a7', '#ed4c78']


const timeseriesData = {
    legends: ['Messages'],
    yAxis: [
        [12, 14, 12, 534, 12, 45, 12]
    ],
    xAxis: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'
    ],
    barWidth: 100,
}


const discordRoles = [{
    label: 'Dragon-owner',
    color: 'primary'
}, {
    label: 'Top contributor',
    color: 'warning'
}, {
    label: 'Early Beliver',
    color: 'success'
}]


const messages = [{
    title: '#general-chat',
    icon: <i class='tio-message'></i>,
    description: "Hi Jaikishan, to use the Intract bot for your community, you will have to register with us first given its live in a closed beta set up. Let me know if you would like to know more.",
    time: moment().subtract(4, 'd').fromNow(),
    tags: [{value: 'positive', label: 'Positive sentiment', color: 'success'}]
}, {
    title: '#general-chat',
    icon: <i class='tio-message'></i>,
    description: "Hey, all is well. Will be soon posting some key updates about our product launch here.",
    time: moment().subtract(4, 'd').fromNow(),
    tags: [{value: 'positive', label: 'Positive sentiment', color: 'success'}]
}, {
    title: '#general-chat',
    icon: <i class='tio-message'></i>,
    description: "Hi ! Glad to have you in our community. Lots of events & fun stuff planned in the coming weeks. Happy to take your suggestions on the same. Cheers 🙂",
    time: moment().subtract(4, 'd').fromNow(),
    tags: [{value: 'positive', label: 'Positive sentiment', color: 'success'}]
}, {
    title: '#general-chat',
    icon: <i class='tio-message'></i>,
    description: "Hi ! Awesome to connect. Check out our about us for more information and tagging a post here for our notion doc. Let's get on a call sometime - dm me your slots may be?",
    time: moment().subtract(4, 'd').fromNow(),
},{
    title: 'Twitter',
    icon: <i class='tio-twitter'></i>,
    description: "Hi ! Awesome to connect. Check out our about us for more information and tagging a post here for our notion doc. Let's get on a call sometime - dm me your slots may be?",
    time: moment().subtract(4, 'd').fromNow(),
}, {
    title: '#general-chat',
    icon: <i class='tio-message'></i>,
    description: "Hey Mehul, Glad to have you here. And thanks for your kind words. Sure, we will do a product feedback session very soon. And would love to take your inputs",
    time: moment().subtract(4, 'd').fromNow(),
}, {
    title: '#general-chat',
    icon: <i class='tio-message'></i>,
    description: "Oh feel you so much 😛 Long hours have become a norm",
    time: moment().subtract(4, 'd').fromNow(),
    tags: [{value: 'positive', label: 'Positive sentiment', color: 'success'}]
}]


