import axiosInstance from '../axios';


export const loginUser = async ({email, password}) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/auth/login', {
            email: email.toLowerCase().trim(), 
            password,
        })
        if(success) return data
        

    }catch(err){
        console.log(err)
        throw err
    }
}



export const getUser = async () => {
    try{

        const {data: {success, data}} = await axiosInstance.get('/auth/user')
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const logoutUser = async () => {
    try{

        const {data: {success, data}}  = await axiosInstance.get('/auth/logout')
        return data;

    }catch(err){
        throw err
    }
}


export const createUser = async ({email, firstName, lastName, password, shopifyDetails}) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/auth/register', {
            email: email.toLowerCase().trim(), 
            firstName, 
            lastName, 
            password,
            shopifyDetails
        })
        if(success) return data;

    }catch(err){
        console.log(err)
        throw err
    }
}



export const createWorkplace = async (body) => {
    try{

        const {data: {success, data}} =await axiosInstance.post('/auth/create-workplace', {
            ...body
        })
        return data.data;

    }catch(err){
        console.log(err)
        throw err
    }
}




export const resendEmailConfirmation = async () => {
    try{

        const {data: {success, data}} = await axiosInstance.get('/auth/send-verification-email')
        if(success) return data;

    } catch(err){
        console.log(err)
        throw err
    }
}


export const resetPassword = async ({password, hash}) => {
    try{

        const {data: {success, data}} = await axiosInstance.put('/auth/reset-password', {
            password, hash
        })
        if(success) return data;

    } catch(err){
        console.log(err)
        throw err
    }
}



export const forgotPassword = async ({email}) => {
    try{
        const {data: {success, data}} = await axiosInstance.post('/auth/forgot-password', {
            email: email.toLowerCase().trim()
        })
        if(success) return data;

    } catch(err){
        console.log(err)
        throw err
    }
}


export const verifyEmail = async (hash) => {
    try{
        const {data: {success, data}} = await axiosInstance.post('/auth/confirm-account', {
            hash
        })
        if(success) return data;

    } catch(err){
        console.log(err)
        throw err
    }
}




export const updatePasswordApi = async (currentPassword, newPassword) => {
    try{
        const {data: {success, data}} = await axiosInstance.put('/auth/update-password', {
            currentPassword, 
            newPassword,
        })
        return data

    }catch(err){
        console.log(err)
        return err
    }
}

