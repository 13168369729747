import React, {useState, useEffect, useContext} from 'react'
import AuthLayout from 'components/Layout/Auth'
import SelectField from 'components/Forms/Select'
import {Button} from 'components/Forms/Buttons'
import {InputField, PasswordField} from 'components/Forms/TextInput'
import {createWorkplace} from 'services/user/auth'
import validation from 'utilis/validation'
import {Store} from 'store'
import {updateUser} from 'store/actions/auth'
import {updateWorkplace} from 'store/actions/workplace'
import { getDiscordAuthLinkApi } from 'services/workplace'
import toast from 'react-hot-toast'

const CreateWorkplace = (props) => {

    const {state, dispatch} = useContext(Store)
    const [values, setValues] = useState({workplaceName: '', telegram: '', twitter: '',  useCase: '', code: '', password: '', password1: ''});
    const [errors, setErrors] = useState({workplaceName: false, telegram: false, twitter: false, useCase: false, code: false, password: false, password1: false})
    const [loading, setLoading] = useState(false);

    
    const submitResponse = async () => {
        try{
            if(!validateData()) return;
            setLoading(true);
            const workplace = await createWorkplace({
                ...values
            });
            const link = await getDiscordAuthLinkApi()
            toast.success("Redirecting you to discord to add our bot in your server")
            window.location = link;
            setLoading(false);
        } catch(err){
            if(err.response && err.response.data && err.response.data.error === "INVALID_CODE"){
                setErrors(prev => ({...prev, code: true}))
            }
            toast.error('Please enter the right early access code. If you dont have one, please get in touch with sambhav@intract.ai')
            setLoading(false);
            console.log(err);
        }
    }


    const validateData = () => {
        const new_errors = {workplaceName: false, telegram: false, twitter: false, useCase: false, code: false}
        if(validation.isEmpty(values.workplaceName)) new_errors.workplaceName = true
        if(validation.isEmpty(values.code)) new_errors.code = true
        // if(validation.isEmpty(values.useCase)) new_errors.useCase = true
        if(values.password !== values.password1) new_errors.password1 = true
        if(!validation.isPassword(values.password)) new_errors.password = true;
        setErrors(new_errors)
        if(Object.values(new_errors).includes(true)) return false;
        else return true;
    }


    const editValues = (name) => (value) => {
        setValues(prev => ({...prev, [name]: value }));
    };

    const editErrors = (name) => (value) => {
        setErrors(prev => ({...prev, [name]: value }));
    };

    useEffect(() => {
        setErrors({workplaceName: false, telegram: false, twitter: false, useCase: false})
    }, [values])

    return(
        <AuthLayout>
        <form>
            <div class=" mb-5">
                <h1 class="display-4">Create your workspace</h1>
            </div>

            <InputField 
                name = "workplace"
                value = {values.workplaceName}
                error = {errors.workplaceName}
                setValue = {editValues('workplaceName')}
                setErrors = {editErrors('workplaceName')}
                placeholder = ""
                label = "What's the name of your community?"
                errorText = "Please enter the name of your community"
                labelClass='h1'
                required
            />
            <InputField 
                name = "code"
                value = {values.code}
                error = {errors.code}
                setValue = {(code_) => editValues('code')(code_.toLowerCase())}
                setErrors = {editErrors('code')}
                placeholder = ""
                label = "Your early access code"
                errorText = "Please enter your early access code."
                labelClass='h1 mt-5'
                required
            />
             {/* <SelectField 
                name = "useCase"
                value = {values.useCase}
                error = {errors.useCase}
                setValue = {editValues('useCase')}
                setErrors = {editErrors('useCase')}
                placeholder = ""
                label = "What is the biggest problem with your community at the moment?"
                errorText = "Please select an option"
                options = {useCaseOptions}
                labelClass='h1 mt-5'
                required
            /> */}
            <InputField 
                name = "telegram"
                value = {values.telegram}
                error = {errors.telegram}
                setValue = {editValues('telegram')}
                setErrors = {editErrors('telegram')}
                placeholder = ""
                label = "Your telegram id or your phone number"
                errorText = "Please enter your telegram id"
                labelClass='h1 mt-5'
            />

            {!state.user.data.passwordHash && <>

                <PasswordField 
                    name = "password"
                    value = {values.password}
                    error = {errors.password}
                    setValue = {editValues('password')}
                    setErrors = {editErrors('password')}
                    label = "Password"
                    errorText = "Please enter a valid password"
                    size = "lg"
                />
                <PasswordField 
                    name = "password"
                    value = {values.password1}
                    error = {errors.password1}
                    setValue = {editValues('password1')}
                    setErrors = {editErrors('password1')}
                    label = "Confirm Password"
                    errorText = "Re-entered Password does not match"
                    size = "lg"
                />

            </>}

            <div class='alert alert-soft-info mt-5' role='alert'>
                Once you complete, you will be redirected to Discord. Please grant all the required bot permissions to integrate successfully, else please get in touch if you face any issues at sambhav@intract.ai
            </div>
           
            <Button 
                text = "Continue"
                loadingText = "Creating your workplace..."
                className = "btn-lg btn-block"
                submitResponse = {submitResponse}
                loading = {loading}
                color = "primary"
            />
      </form>
      </AuthLayout>
    )
}

export default CreateWorkplace




const useCaseOptions = [{
    value: '',
    label: 'Choose one',
},{
    value: 'Protecting against spam / bad actors',
    label: 'Protecting against spam / bad actors',
},{
    value: 'Moderating the conversations / activities',
    label: 'Moderating the conversations / activities',
},{
    value: 'Monetization of your community',
    label: 'Monetization of your community',
},{
    value: 'Recruiting moderators and community managers',
    label: 'Recruiting moderators and community managers',
},{
    value: 'Attracting contributions from members',
    label: 'Attracting contributions from members',
},{
    value: 'Maintaining high engagement levels',
    label: 'Maintaining high engagement levels',
},{
    value: 'Building the community and growing members ',
    label: 'Building the community and growing members ',
}]