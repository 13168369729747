import React, {useEffect, useState, useContext, useRef} from 'react'
import useDidMountEffect from 'hooks/useDidMount'
import Avatar from 'components/Avatar'
import PaginatedTable from 'components/Table/PaginatedTable'
import Filters from 'components/Filters'
import {tokenFiltersJson} from './utilis/filters'
import { getMembersApi, downloadMembersApi, getTotalMembersApi, refreshAllWalletApi } from 'services/members'
import { getAllTagsApi } from 'services/workplace'
import { getTokensApi } from 'services/tokens'
import { getAllSourcesApi } from 'services/sources'
import { PageHeader } from 'components/Text'
import HideModal from './components/Hide'
import TagsModal from './components/Tags'
import Papa from 'papaparse'
import {getAvatar} from 'utilis/avatars'
import toaster from 'react-hot-toast'
import {Store} from 'store'
import { saveMemberTags, saveSources } from 'store/actions/community'
import { updateCryptoPrices, updateTokens } from 'store/actions/workplace'
import Address from 'components/Others/Address'
import {getCoinDecimals, getCoinName} from 'utilis/coins'
import {getNumberWithCommas, abbreviateNumber} from 'utilis/sanitisers'
import { Button } from 'components/Forms/Buttons'
import {getCryptoPriceApi} from 'services/workplace'
import findToken from 'utilis/tokens'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {getSampleMembers, getSampleHeaders} from './utilis/sampleData'


const TokenHolders = (props) => {

    const {state, dispatch} = useContext(Store)
    const [fetchedMembers, setFetchedMembers] = useState([])
    const [members, setMembers] = useState();
    const [showAddTags, setShowAddTags] = useState(false);
    const [showHide, setShowHide] = useState(false);
    const [showAssignRole, setShowAssignRole] = useState(false);
    const [cryptoPrices, setCryptoPrices] = useState();
    const [filters, setFilters] = useState(tokenFiltersJson)
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [selected, setSelected] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [sortBy, setSortBy] = useState({key: 'balance', asc: true});
    const [totalPages, setTotalPages] = useState(4);
    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(false)
    const rowsPerPage = 10;
    const prevFilterCount = useRef()
    const [allTags, setAllTags] = useState([]);
    const [orgTokens, setOrgTokens] = useState();

    useEffect(() => {
        if(props && props.location && props.location.search){
            const search = new URLSearchParams(props.location.search).get("search")
            if(search && search.length > 0) {
                setSearchInput(search)
            } 
        }
        setAppliedFilters(tokenFiltersJson.filter(item => item.default).map(item => ({
            value: item.value, 
            filter: item.defaultValues ? item.defaultValues : (item.type === 'DATE' ? dateFilterNull : item.isMulti ? [] : '')
        })))
    }, [props])

  

    useEffect(() => {
        if(state.community.tags){
            formatTags(state.community.tags)
        } else getAllTags();
    }, [state.community.tags])
  
  
    useEffect(() => {
        if(state.workplace.tokens){
        } else getAllTokens();
    }, [state.workplace.tokens])
    
    useEffect(() => {
        if(state.community.sources){
            formatSources(state.community.sources)
        } else getAllSources();
    }, [state.community.sources])
   
   
    useEffect(() => {
        if(state.workplace.cryptoPrices){
        } else getCryptoPrices();
    }, [state.workplace.cryptoPrices])

    
    const getCryptoPrices = async () => {
        try{

            const prices = await getCryptoPriceApi()
            updateCryptoPrices(prices, dispatch)
            return prices.rates

        } catch(err){
            console.log(err)
        }
    }
    
    
    const getAllTokens = async () => {
        try{

            const tokens_ = await getTokensApi()
            updateTokens(tokens_, dispatch)
            return tokens_

        } catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        fetchTotalMembers()
    }, [searchInput, appliedFilters])
  
   
    useEffect(() => {
        filterMembers()
    }, [currentPage])

    useDidMountEffect(() => {
        if(currentPage === 1) filterMembers()
        else setCurrentPage(1)
        // filterMembers()
    }, [searchInput])
    
    useDidMountEffect(() => {
        let prevFiltersCount = prevFilterCount.current ? (prevFilterCount.current) : 0
        let currentFilterCount = getFilterCount(appliedFilters)
        if(currentFilterCount === prevFiltersCount){
            return;
        }
        if(currentPage === 1) filterMembers()
        else setCurrentPage(1)
        prevFilterCount.current = getFilterCount(appliedFilters)
    }, [appliedFilters])
    
    useDidMountEffect(() => {
        if(currentPage === 1) filterMembers()
        else setCurrentPage(1)
    }, [sortBy])



    const getAllTags = async () => {
        try{
            const allTags_ = await getAllTagsApi();
            saveMemberTags(allTags_, dispatch)
        } catch(err){
            console.log(err)
        }
    }

     
    const getAllSources = async () => {
        try{
            const allSources_ = await getAllSourcesApi();
            saveSources(allSources_, dispatch)
        } catch(err){
            console.log(err)
        }
    }


    const formatTags = (tags_) => {
        try{

            const allFilters = [...filters]
            setAllTags(tags_.map(item => ({value: item.tag, label: item.tag, color: item.color})))
            const index = allFilters.findIndex(item => item.value === 'tags')
            allFilters[index].enums = tags_.map(item => ({value: item.tag, label: item.tag, color: item.color}))
            setFilters(allFilters)

        } catch(err){
            console.log(err)
        }
    }
   
    const formatSources = (sources_) => {
        try{
            const allFilters = [...filters]
            const index = allFilters.findIndex(item => item.value === 'source')
            allFilters[index].enums = sources_.map(item => ({value: item.integrationId, label: item.name}))
            setFilters(allFilters)
        } catch(err){
            console.log(err)
        }
    }
    
    const filterMembers = async () => {
        await fetchMembers(getUrlParams())
    }

    const getUrlParams = () => {
        const urlParams = new URLSearchParams();
        urlParams.append('sourceType', 'WEB3')
        urlParams.append('page', currentPage)
        urlParams.append('limit', rowsPerPage)
        urlParams.append('search', searchInput)
        if(sortBy && sortBy.key){
            urlParams.append('sortBy', getSortKey(sortBy.key))
            urlParams.append('sortDirection', sortBy.asc)
        }
        if(appliedFilters.length > 0){
            appliedFilters.forEach(filter => {
                if(filter.filter.length > 0){
                    urlParams.append(filter.value, filter.filter)
                    if(filter.value === 'level'){
                        urlParams.delete('sortBy')
                        urlParams.delete('sortDirection')
                        urlParams.append('sortBy', getSortKey('activityCount'))
                        urlParams.append('sortDirection', true)
                    }
                }
                if(new Date(filter.filter.startDate).getTime() > 0){
                    urlParams.append(filter.value + 'StartDate', filter.filter.startDate)
                    urlParams.append(filter.value + 'EndDate', filter.filter.endDate)
                }
            })
        }
        return urlParams
    }

    const fetchTotalMembers = async () => {
        try{
            let urlParams = getUrlParams()
            const totalMembers_ = await getTotalMembersApi(urlParams);
            setTotalPages(Math.ceil(totalMembers_ / rowsPerPage))

        } catch(err){
            console.log(err)
        }
    }

    const fetchMembers = async (urlParams) => {
        try {
            setMembers()
            // const fetchedMembers = await getMembersApi(urlParams);
            // await formatMembers(fetchedMembers.docs)
            const fetchedMembers = await getSampleMembers(urlParams);
            setMembers(fetchedMembers.docs)
        } catch (error) {
            console.log(error)
        }
    }

    const formatMembers = async (fetchedMembers) => {
        let prices = state.workplace.cryptoPrices.rates
        let tokens = state.workplace.tokens
        if(!prices){
            prices = await getCryptoPrices()
        }
        if(!tokens){
            tokens = await getAllTokens()
        }
        setMembers(fetchedMembers.map(item => ({
            ...item,
            username: {
                label: <Avatar 
                    name = {item.metadata.username}
                    avatar = {(item.metadata.avatar)}
                    link = {`/app/member/${item._id}`}
                />,
                value: item.username,
            },
            wallet: {
                label: <Address address = {item.web3MemberProfiles[0].address}/>,
                value: item.web3MemberProfiles[0].address
            },
            tags: {
                label: (item.adminInputs && item.adminInputs.tags && item.adminInputs.tags.length > 0) ? <div style = {{maxWidth: '140px'}}>
                    {item.adminInputs.tags.map(item2 => <span class={`badge badge-soft-${item2.color ? item2.color : 'primary'} mr-2`}>
                        {item2.label}
                    </span>)}
                </div> : <></>,
                value: (item.adminInputs && item.adminInputs.tags && item.adminInputs.tags.length > 0) ? item.adminInputs.tags.map(item => item.label).join(', ') : '',
            },
            balance: getTotalBalance(item.web3MemberProfiles, prices),
            chain: {
                label: <span class={`badge badge-soft-primary mr-2`}>{item.web3MemberProfiles[0].chain}</span>,
                value: item.web3MemberProfiles[0].chain
            },
            tokens: item.web3Metadata.numTokens,
            nfts: item.web3Metadata.numNfts,
            topPopularTokens: getTopPopularTokens(item.web3MemberProfiles),
            topTokens: item.web3Metadata.numTokens,
            orgTokens: getOrgTokens(item.web3MemberProfiles, tokens)
        })))
    }

    const exportMembers = (selected) => {
        try{

            const dataToBeDownloaded = members.filter(item => selected.includes(item._id)).map(item => ({
                'Username': item.username.value,
                'Organization Name': item.adminInputs?.organization?.name,
                'Tags': item.tags.value,
                'Address': item.wallet.value,
                'Balance': item.balance,
                'chain': item.chain.value,
                'tokens': item.tokens,
                'nfts': item.nfts,
            }))
            const csv = Papa.unparse(dataToBeDownloaded)
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'members.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch(err){
            console.log(err)
        }
    }

    const downloadData = async (type) => {
        try{
            const params = getUrlParams()
            
            const res = await downloadMembersApi(params)
            toaster.success("We have emailed you a copy of your members data")

        } catch(err){
            console.log(err)
        }
    }
  
  
    const refreshWallet = async (type) => {
        try{
            setLoading(true)
            const res = await refreshAllWalletApi()
            toaster.success("We are updating wallet data of your users! It might take few minutes")
            setLoading(false)
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    const selectedOptions = [
    {
        label: 'Tag',
        function: () => setShowAddTags(true)
    }, {
        label: 'Export',
        function: (selected) => exportMembers(selected)
    }]
    
    return (
        <div>
            <PageHeader 
                title = "👨‍👩‍👧‍👦 Your on-chain community"
                description="Understand the on-chain behavior of all your connected wallets"
                button={
                    <Button
                        text="Refresh address data"
                        submitResponse={() => refreshWallet()}
                        loading = {loading}
                    />
                }
            />
            <div class='card mt-4'>
                <PaginatedTable 
                    headers = {getSampleHeaders('token')} 
                    data = {members}
                    rowSelection = {true}
                    searchPlaceholder = "Search by username"
                    headerButtons = {
                        <Filters 
                            position="end"
                            position2="end"
                            filters = {filters}
                            appliedFilters = {appliedFilters}
                            setAppliedFilters = {setAppliedFilters}
                            clearable
                        />
                    }
                    selectionOptions = {selectedOptions}
                    rowsPerPage = {rowsPerPage}
                    currentPage = {currentPage} 
                    setCurrentPage = {setCurrentPage}
                    sortBy = {sortBy} 
                    setSortBy = {setSortBy}
                    totalPages = {totalPages} 
                    setTotalPages = {setTotalPages}
                    searchInput = {searchInput} 
                    setSearchInput = {setSearchInput}
                    selected = {selected}
                    setSelected = {setSelected}
                    downloadData = {downloadData}
                    loadingOnEmpty = {true}
                    downloadable
                />
            </div>
            <HideModal 
                show = {showHide}
                setShow = {setShowHide}
                members = {members}
                setMembers = {setMembers}
                selected = {selected}
                setSelected = {setSelected}
                filterMembers = {filterMembers}
            />
            <TagsModal 
                show = {showAddTags}
                setShow = {setShowAddTags}
                members = {members}
                setMembers = {setMembers}
                selected = {selected}
                setSelected = {setSelected}
                allTags = {allTags}
            />
        </div>
    )
}

export default TokenHolders





const headers = [{
    value: 'username',
    label: 'Username'
}, {
    value: 'wallet',
    label: 'Address'
}, {
    value: 'tags',
    label: 'tags'
},  {
    value: 'balance',
    label: 'Native Balance',
    tooltip: 'Represents total USD value locked in the native chain',
}, {
    value: 'topPopularTokens',
    label: 'Popular Tokens',
    noSort: true
}, {
    value: 'orgTokens',
    label: 'Org Tokens',
    noSort: true
}, {
    value: 'topTokens',
    label: 'Token Holding' 
}, {
    value: 'nfts',
    label: 'NFTs Holding' 
}]



const getSortKey = (value) => {
    switch(value){
        case 'username':
            return 'metadata.username'
        case 'wallet':
            return 'sources.address'
        case 'tags':
            return 'adminInputs.tags'
        case 'balance':
            return 'web3Metadata.balance'
        case 'topTokens':
            return 'web3Metadata.numTokens'
        case 'nfts':
            return 'web3Metadata.numNfts'
        default:
            return 'username'
    }
}
  



const dateFilterNull = {
    startDate: new Date(null), 
    endDate:new Date(null)
}




const getFilterCount = (appliedFilters) => {
    let filterCount = 0
    if(appliedFilters.length > 0){
        appliedFilters.forEach(filter => {
            if(filter.filter.length > 0){
                filterCount = filterCount + getHashFromArray(filter.filter)
            }
            if(new Date(filter.filter.startDate).getTime() > 0){
                filterCount = filterCount + new Date(filter.filter.startDate).getTime() + new Date(filter.filter.endDate).getTime()
            }
        })
    }
    return filterCount
}

const getHashFromArray = (array) => {
    let hash = 0
    if(typeof array === 'number') return array
    if(typeof array === 'string') return uniqueNumberFromString(array)

    if(array.length > 0){
        array.forEach(item => {
            hash = hash + uniqueNumberFromString(item)
        })
    }
    return hash
}

const uniqueNumberFromString = ( string ) => {
    var hash = 0, i, chr, len;
    if (string.length === 0) return hash;
    for (i = 0, len = string.length; i < len; i++) {
      chr   = string.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
};


  const getTotalBalance = (web3Profiles, rates) => {
    let totalBalance = 0
    web3Profiles.forEach(profile => {
        if(profile.nativeBalance){
            if(profile.chain === 'polygon'){
                totalBalance = totalBalance + (profile.nativeBalance * rates.matic * 10E-19)
            }
            if(profile.chain === 'eth'){
                totalBalance = totalBalance + (profile.nativeBalance * rates.eth * 10E-19)
            }
            if(profile.chain === 'solana'){
                totalBalance = totalBalance + (profile.nativeBalance * rates.solana * 10E-10)
            }
            if(profile.chain === 'ronin'){
                totalBalance = totalBalance + (profile.nativeBalance * rates.ronin * 10E-19)
            }
        }
    })
    return getNumberWithCommas(parseFloat(totalBalance).toFixed(2)) + ' USD'
}


const getTopPopularTokens = (web3Profiles) => {
    const allTokens = web3Profiles.filter(item => item.chain !== 'solana').map(item => item.tokens).flat()
    const addresses = allTokens.map(item => item.address)
    const topTokens = findToken(addresses)
    if(topTokens){
        return {
            label: (
                <div style = {{maxWidth: '140px',  'whiteSpace': 'pre-line'}}>
                    {topTokens.slice(0, 3).map(item => 
                    <OverlayTrigger overlay = {<Tooltip>
                        Balance: {abbreviateNumber(allTokens.find(item1 => item1.address === item.address).balance/(10**allTokens.find(item1 => item1.address === item.address).decimals))}
                    </Tooltip>}><span class='badge badge-soft-primary align-items-center d-flex mr-2 my-1'>
                        <div class={`avatar avatar-xss avatar-soft-secondary avatar-circle mr-2`}>
                            <img class="avatar-img" src={item.logoURI} alt="" />
                        </div>
                        {item.name.length > 10 ? item.name.slice(0, 10) + '...' : item.name}
                    </span></OverlayTrigger>)}
                    {topTokens.length > 3 && <span class='badge badge-soft-primary align-items-center mr-2 my-1'>
                        +{topTokens.length - 3}
                        </span>
                    }
                </div>
            ),
            value: topTokens.length
        }
    }
}

const getTopTokens = (web3Profiles) => {
    const allTokens = web3Profiles.map(item => item.tokens.map(item2 => ({...item2, chain: item.chain}))).flat().sort((a, b) => b.balance - a.balance)
    return {
        label: (
            <div style = {{minWidth: '140px',  'whiteSpace': 'pre-line'}}>
                {allTokens.slice(0, 3).map(item => 
                <OverlayTrigger overlay = {<Tooltip>
                    Balance: {item.chain === 'solana' ? abbreviateNumber(item.balance) : abbreviateNumber(item.balance/(10**item.decimals))}
                </Tooltip>}><span class='badge badge-soft-info align-items-center mr-2 my-1'>
                        {item.chain !== 'solana' && (item.name?.length > 10 ? item.name.slice(0, 10) + '...' : item.name)}
                        {item.chain === 'solana' && (item.address?.length > 5 ? item.address.slice(0, 5) + '...' : item.address)}
                </span></OverlayTrigger>)}
                {allTokens.length > 3 && <span class='badge badge-soft-success align-items-center mr-2 my-1'>
                    +{allTokens.length - 3}
                    </span>
                }
            </div>
        ),
        value: allTokens.length
    }
}



const getOrgTokens = (web3Profiles, tokens) => {
    if(!web3Profiles || !web3Profiles.map(item => item.tokens ? true : false).includes(true)) return ''
    const allTokens = web3Profiles.map(item => item?.tokens.map(item2 => ({...item2, chain: item.chain}))).flat()
    if(!allTokens || allTokens.length === 0) return ''
    const orgTokens = allTokens.filter(item => tokens.map(item => item.address).includes(item.address))

    return {
        label: (
            <div style = {{minWidth: '140px',  'whiteSpace': 'pre-line'}}>
                {orgTokens.slice(0, 3).map(item => 
                   <span class='badge badge-soft-primary align-items-center mr-2 my-1'>
                    
                        {item.chain !== 'solana' && (item.name?.length > 10 ? item.name.slice(0, 10) + '...' : item.name)}
                        {item.chain === 'solana' && (item.address?.length > 5 ? item.address.slice(0, 5) + '...' : item.address)}
                        : {' '}
                        {item.chain === 'solana' ? abbreviateNumber(item.balance) : abbreviateNumber(item.balance/(10**item.decimals))}
                </span>)}
                {orgTokens.length > 3 && <span class='badge badge-soft-success align-items-center mr-2 my-1'>
                    +{orgTokens.length - 3}
                    </span>
                }
            </div>
        ),
        value: orgTokens.length
    }
}


const getTopNfts = (web3Profiles) => {
    const allTokens = web3Profiles.map(item => item.nfts.map(item2 => ({...item2, chain: item.chain}))).flat()
    return {
        label: (
            <div style = {{minWidth: '140px',  'whiteSpace': 'pre-line'}}>
                {allTokens.slice(0, 3).map(item => 
                <OverlayTrigger overlay = {<Tooltip>
                    {item.chain !== 'solana' && `Token Id: ${item.tokenId}`}
                    {item.chain === 'solana' && `Mint id: ${item.mint}`}
                </Tooltip>}><span class='badge badge-soft-info align-items-center mr-2 my-1'>
                        {(item.chain !== 'solana' && item.name) && (item.name.length > 7 ? item.name.slice(0, 7) + '...' : item.name)}
                        {(item.chain === 'solana' && item.address) && (item.address.length > 5 ? item.address.slice(0, 5) + '...' : item.address)}
                </span></OverlayTrigger>)}
                {allTokens.length > 3 && <span class='badge badge-soft-success align-items-center mr-2 my-1'>
                    +{allTokens.length - 3}
                    </span>
                }
            </div>
        ),
        value: allTokens.length
    }
}