import React, { useEffect } from 'react'
import Discord from './Components/Discord'
import Airdrop from './Components/Airdrop'
import Email from './Components/Email'
import SoulBound from './Components/SoulBound'



const CreateCampaign = (props) => {

    if(props.match.params.id === 'airdrop') {
        return <Airdrop />
    }
    if(props.match.params.id === 'discord-message') {
        return <Discord />
    }
    if(props.match.params.id === 'soul-bound') {
        return <SoulBound />
    }
    if(props.match.params.id === 'email') {
        return <Email />
    }
    return <></>
}

export default CreateCampaign