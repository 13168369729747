import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'

const Header = () => {

    useEffect(()=> {
        //change the class for the body tag
        document.body.classList.remove('has-navbar-vertical-aside')
        document.body.classList.remove('navbar-vertical-aside-show-sm')
        document.body.classList.remove('navbar-vertical-aside-show-lg')

    })
    
    return (
    <header id="header" class="navbar navbar-expand-xl navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered  navbar-dark">
        <div class="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">
            <div class="navbar-brand-wrapper">
                <Link to = "/app" class="navbar-brand" >
                    <img class="navbar-brand-logo" src="/assets/svg/logos/logo.svg" alt="Logo" />
                </Link>
            </div>
            <div class="navbar-nav-wrap-content-right">
                <ul class="navbar-nav align-items-center flex-row">
                    <li class='nav-item'>
                        <Link to="/app/journeys" class='btn btn-sm px-4 btn-outline-light'>Exit</Link>
                    </li>
                    <li class='nav-item'>
                        <div class='btn btn-sm px-4 btn-light'>Review and Turn on</div>
                    </li>
                </ul>
            </div>
        </div>
    </header>
    )
}

export default Header