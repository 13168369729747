import Chart from 'components/Charts'
import { PageHeader } from 'components/Text'
import React, {useState} from 'react'
import SampleGraphs from './components/SampleGraphs'
import filtersJson, {initialFilters} from '../Report/utilis/filters.js'
import ReportChartComponent from './components/ChartComponent.js'
import Filters from './components/Filters'
import HealthAtGlance from './components/HealthAtGlance'

const AnalyzeContract = () => {

	const [appliedFilters, setAppliedFilters] = useState(initialFilters);
	const [filters, setFilters] = useState(filtersJson);

  	return (
    	<div>
			<PageHeader 
				title = "Smart Contract and wallet Analytics"
				description = "Track wallet interactions with your smart contracts and analyze the on-chain behaviour of your connected wallets ."
			/>
			<Filters 
                filters = {filters}
                appliedFilters = {appliedFilters}
                setAppliedFilters = {setAppliedFilters}
            />
			<HealthAtGlance />
			<SampleGraphs />
			<ReportChartComponent
                title = "Number of Interactions"
                description = ""
                timeseriesData = {metrics.dailyInteractions}
                metadata = {metadata.dailyInteractions}
            />
		</div>
  	)
}

export default AnalyzeContract


const metrics = {
	dailyInteractions: {
		legends: ['Count'],
		yAxis: [
			[2314, 3311, 3452, 3521, 3643, 3665, 3690, 3712, 3764, 3845, 3923, 4012, 4104, 4201 ]
		],
		xAxis: [
			'1st Aug', '2nd Aug', '3rd Aug', '4th Aug', '5th Aug', '6th Aug', '7th Aug', '8th Aug', '9th Aug', '10th Aug', '11th Aug', '12th Aug', '13th Aug', '14th Aug', '15th Aug'
		],
		yPrefix: '',
		stepsize: 2,
		barWidth: 100
	}
}

const metadata = {
	dailyInteractions: {
		title: "Number of Interactions",
        metric: "51,157 " ,
        metricType: 'total interactions',
	},

}




const SourceOfFundsChart = () => {
	return (
		<div class='card h-100'>
			<div class='card-header'>
				<h4 class='text-center'>Source of funds</h4>
			</div>
			<div class="card-body">
				<Chart 
					type='doughnut'
					datasets = {[[12, 23, 5]]}
					labels = {['Coinbase', 'Binance', 'Binance']}
					stepsize = {10}
					yprefix = " "
					height = "16rem"
					id = "chartjs-7"
				/>
				<div class="row justify-content-center mt-4 ">
					{['Coinbase', 'Binance', 'Binance'].map((item,index) => <div class="col-auto">
						<span 
							class={`legend-indicator`}
							style = {{
								backgroundColor: colors[index]
							}}
						></span> {item}
					</div>)}
				</div>
			</div>
		</div>
	)
}

const colors = ['#377dff', '#00c9db', '#e7eaf3']
