import topTokens from 'utilis/tokens/tokens.json'
import topNFts from 'utilis/tokens/nfts.json'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Avatar, { AvatarGroup } from 'components/Avatar'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {getNumberWithCommas} from 'utilis/sanitisers'




const getTopPopularTokens = (tokens) => {
    if(tokens){
        return {
            label: (
                <div style = {{maxWidth: '140px',  'whiteSpace': 'pre-line'}}>
                    <AvatarGroup 
                        users = {tokens}
                        maxLength = {4}
                        size = "sm"
                    />
                </div>
            ),
            value: tokens.length
        }
    }
}



const getRandomTokens = (tokens, isNft) => {
    let randomTokens = []
    let max = 5+ Math.floor(Math.random() * 10)
    for (let i = 0; i < max; i++) {
        let token = tokens[Math.floor(Math.random() * tokens.length)]
        randomTokens.push({
            name: token.symbol + (isNft ?  '' :  `, Value: ${Math.floor(Math.random() * 1000)}`),
            avatar: token.logoURI,
        })
    }
    return getTopPopularTokens(randomTokens)
}


const randomAddress = () => {
    return '0x' + randomString(3) + '...' + randomString(6)
}
const randomDate = (startDate, endDate) => {
    return moment(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime())).format('Do MMM YY')
}
const randomNumber = (num) => {
    return Math.floor(Math.random() * num)
}
const randomBoolean = () => {
    return Math.random() > 0.5
}
const randomString = (length) => {
    let result = ''
    let characters = 'ABCDEFGHIJXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}
const randomSubsetOfArray = (array, max) => {
    let randomSubset = []
    if(!max) max = Math.floor(Math.random() * array.length)
    for (let i = 0; i < max; i++) {
        randomSubset.push(array[Math.floor(Math.random() * array.length)])
    }
    return randomSubset.filter((item, index) => randomSubset.indexOf(item) === index)
}

const labels = [{
    name: 'Crypto Whale',
    color: 'primary',
},{
    name: 'NFT newbie',
    color: 'warning',
},{
    name: 'Token Watcher',
    color: 'warning',
},{
    name: 'Likely a bot',
    color: 'danger',
},{
    name: 'Potential User',
    color: 'primary',
},{
    name: 'Active Voter',
    color: 'success',
}]

const sourceOfFunds = [
    {name: 'Coinbase', logo: 'https://images.ctfassets.net/q5ulk4bp65r7/1rFQCqoq8hipvVJSKdU3fQ/21ab733af7a8ab404e29b873ffb28348/coinbase-icon2.svg'},
    {name: 'Binance', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Binance_Logo.svg/480px-Binance_Logo.svg.png'},
    {name: 'Gemini', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Gemini_symbol_%28bold%29.svg/1200px-Gemini_symbol_%28bold%29.svg.png'},
]
const names = ['sambhavjain.eth', 'abhishek.eth', 'arcane.nft', 'monkeylol.nft', 'cartman.sol', 'apurvkaushal.eth', 'moeiman.eth', 'monkeylol.sol', 'sasha0x1.eth', 'sashilkumar.sol', 'billrob.sol']
const randomNames = ['Sambhav Jain', 'jake', 'Suryansh Verma', 'phenol', 'bansal | nfticker.info', 'Sashil', '0xAlex', 'sasha0x1', 'abhishek', 'sambhav', 'arcane', 'monkeylol', 'cartman', 'apurvkaushal', ]
// 972012718758440970


const getProjectAssets = (index) => {

    const tokens = [{
        name: '$INTR: 100',
        avatar: '/assets/svg/logos/logo-short.svg',
        symbol: 'INTR',
    }]
    const num = randomNumber(10)
    if(index === 0 || num%2 === 0){
        return {
            value: '',
            label: <div style = {{maxWidth: '140px',  'whiteSpace': 'pre-line'}}>
                <AvatarGroup 
                    users = {tokens}
                    maxLength = {4}
                    size = "sm"
                />
            </div>
        }
    } else {
        return ''
    }
}





export const getSampleMembers = () => {
   
    
    let tenUniqueRandomeNumbers = Array(40).fill(null).map(item => randomNumber(50))
    tenUniqueRandomeNumbers = tenUniqueRandomeNumbers.filter((item, index) => tenUniqueRandomeNumbers.indexOf(item) === index)


    const sampleData = Array(10).fill(null).map((item, index) => {

        let twoRandomValues = [randomNumber(50), randomNumber(40)]
        let labels_ = randomSubsetOfArray(labels, 2)


        return {
            username: {
                label: <Avatar
                    name = {randomNames[index]}
                    avatar = {index === 0 ? '/assets/avatars/01.png' : `https://i.pravatar.cc/150?img=${tenUniqueRandomeNumbers[index]}`}
                    link = {`/app/member/62f0d67d5c831e4dcdab410a`}
                    description = {`${randomNumber(100)} followers`}
                    size = "sm"
                />, 
                value: "",
            },
            address: {
                label: <Link to = "/app/member/62f0d67d5c831e4dcdab410a">
                     <div>{index === 0 ? 'sambhavjain.eth' : randomAddress()}</div>
                     {index === 0 && <small class='text-muted'>{randomAddress()}</small>}
                </Link>, 
                value: "",
            },
            labels: {
                value: '',
                label: <div style = {{maxWidth: '130px', 'whiteSpace': 'pre-line'}}>{labels_.slice(0, 2).map(item2 => 
                    <span class={`badge mr-2 badge-soft-${item2.color}`}>{item2.name}</span>
                )}
                { labels_.length > 2 ? <span class={`badge badge-soft-primary mr-2`}>+{labels_.length - 2}</span> : <></> }
                </div>
            },
            netWorth: '$' + getNumberWithCommas(randomNumber(5000)), 
            txnValue: '$' + getNumberWithCommas(randomNumber(15000)),
            sourceOfFunds: {
                value: '',
                label: <div>
                    {randomSubsetOfArray(sourceOfFunds, 2).slice(0, 2).map((item, index) => (
                        <div class='d-flex justify-content-between align-items-center'>
                            <div class='d-flex align-items-center'>
                                <img class='avatar-img avatar-xss mr-1 border' src={item.logo} alt={item.name} />
                                <>{item.name}</>
                            </div>
                            <div class=''>{twoRandomValues[index]}%</div>
                        </div>
                    ))}
                     <div class='d-flex justify-content-between align-items-center'>
                            <div class='d-flex align-items-center'>
                                {/* <img class='avatar-img avatar-xss mr-1 border' src={item.logo} alt={item.name} /> */}
                                <>Others</>
                            </div>
                            <div class=''>{100-twoRandomValues[0]-twoRandomValues[1]}%</div>
                        </div>
                </div>
            },
            walletAge: randomNumber(600) + ' days',
            projectAssets : getProjectAssets(index),
            topTokens: getRandomTokens(topTokens),
            blueChipNfts: getRandomTokens(topNFts, true),
            lastActivity: randomDate(new Date(2018, 0, 1), new Date()),
            joinedOn: randomDate(new Date(2018, 0, 1), new Date(2022, 0, 1)),
            walletConnected: {
                label:  randomBoolean() ? <div class='badge badge-soft-success'><i class='tio-ethereum-outlined mr-1'></i>Connected</div> : <></>,
                value: '',
            },
            engagementScore: randomNumber(100)+'/100',
            sentiment: {
                label: <div class='badge badge-soft-success'>{randomNumber(100)}% Positive</div>,
                value: '',
            }
        }
    })
    return {
        docs: sampleData
    }
}


export const getSampleHeaders = (type) => {

    const overallHeaders = [{
        value: 'username',
        label: 'Username'
    }, {
        value: 'labels',
        label: 'Labels'
    }, {
        value: 'txnValue',
        label: 'Total Txn Value'
    }, {
        value: 'lastActivity',
        label: 'Last Activity'
    }, {
        value: 'walletAge',
        label: 'Wallet age'
    }, {
        value: 'projectAssets',
        label: 'Project assets'
    }, {
        value: 'topTokens',
        label: 'Top 5 assets'
    }, {
        value: 'comms',
        label: 'Comms Channels'
    },]


    const tokenHeaders = [{
        value: 'address',
        label: 'Address'
    }, {
        value: 'labels',
        label: 'Labels'
    },  {
        value: 'netWorth',
        label: 'Net worth'
    }, {
        value: 'txnValue',
        label: 'Total Txn Value'
    },{
        value: 'walletAge',
        label: 'Wallet age'
    }, {
        value: 'projectAssets',
        label: 'Project assets'
    }, {
        value: 'topTokens',
        label: 'Top 5 assets'
    }, {
        value: 'blueChipNfts',
        label: 'Blue chip NFTs'
    }]

    const discordHeaders = [{
        value: 'username',
        label: 'Username'
    }, {
        value: 'labels',
        label: 'Label'
    }, {
        value: 'stage',
        label: 'Stage'
    }, {
        value: 'engagementScore',
        label: 'EngagementScore'
    }, {
        value: 'sentiment',
        label: 'Sentiment'
    }, {
        value: 'lastActive',
        label: 'LastActive'
    }, {
        value: 'joinedOn',
        label: 'Joined On'
    }, {
        value: 'walletConnected',
        label: 'Connected wallet'
    }]

    if(type == 'overall'){
        return overallHeaders
    }
    else if(type == 'token'){
        return tokenHeaders
    }
    else if(type == 'discord'){
        return discordHeaders
    }
    
}


