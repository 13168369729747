import React, {useState} from 'react'
import { SimpleModal } from 'components/Models/Modal'
import topTokens from 'utilis/tokens/tokens.json'

const tokens = topTokens.map(item => ({
    ...item,
    _balance: Math.floor(Math.random() * 1000)
}))

const SelectCoin = ({show, setShow, setToken}) => {

    const [search, setSearch] = useState('')

    return (
        <SimpleModal
            show={show}
            setShow = {setShow}
            title = "Select a token"
            footer = {<Footer />}
        >
            <Search search = {search} setSearch = {setSearch}/>
            {/* <NativeCurrencies setToken = {setToken}/> */}
            <ul class='list-group list-group-flush mx-n3' >
                {tokens
                    .filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
                    .slice(0, 5)
                    .map(item => <SearchRow {...item} setToken = {setToken} setShow = {setShow}/>)
                }
            </ul>
        </SimpleModal>
    )
}

export default SelectCoin


const Search = ({search, setSearch}) => {
    return (
        <div class="form-group">
            <div class="input-group input-group-merge mb-2 mb-sm-0">
                <div class="input-group-prepend" id="fullName">
                    <span class="input-group-text">
                        <i class="tio-search"></i>
                    </span>
                </div>
                <input 
                    type="text" 
                    class="form-control" 
                    placeholder="Search your token" 
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>
            <a class="btn btn-block btn-primary d-sm-none">Invite</a>
        </div>
    )
}


const Footer = () => {
    return (
        <div class="row align-items-center flex-grow-1 mx-n2">
            <div class="col-sm-8 mb-2 mb-sm-0">
                <input type="hidden" id="publicShareLinkClipboard" value="https://themes.getbootstrap.com/product/front-multipurpose-responsive-template/" />
                <p class="modal-footer-text">Cant find your token here?</p>
            </div>
            <div class="col-sm-4 text-sm-right">
                <a class="js-clipboard btn btn-sm btn-white text-nowrap">
                    <i class="tio-link mr-1"></i> Import your token
                </a>
            </div>
        </div>
    )
}



const SearchRow = ({setToken, name, setShow, symbol, _balance, logoURI}) => {
    return (
        <li class='list-group-item list-group-item-action pointer' onClick = {() => {
            setToken(name)
            setShow(false)
        }}>
            <div class="media">
                <div class="avatar avatar-sm avatar-circle avatar-soft-primary mr-3">
                    <img src={logoURI} alt='avatar' className = "avatar-img"/>
                </div>
                <div class="media-body">
                    <div class="row align-items-center">
                        <div class="col-12 col-sm">
                            <h5 class="text-body mb-0">{symbol}</h5>
                            <span class="d-block font-size-sm">{name}</span>
                        </div>
                        <div class="col-12 col-sm">
                            <span class='d-sm-flex justify-content-sm-end'>
                                {_balance} coins
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}



const NativeCurrencies = () => {
    return (
        <div class="alert py-3 alert-light border-top border-bottom text-center card-alert mx-n4 mb-4" role="alert">
            <div class='mb-2 bold'>Native Currencies & Stablecoins</div>
            <div className='d-flex justify-content-around'>
                <div class='btn btn-soft-primary w-100 btn-sm mx-3'>Matic</div>
                <div class='btn btn-soft-primary w-100 btn-sm mx-3'>USDC</div>
                <div class='btn btn-soft-primary w-100 btn-sm mx-3'>DAI</div>
            </div>
        </div>
    )
}

