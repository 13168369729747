import { Button } from 'components/Forms/Buttons'
import { InputField } from 'components/Forms/TextInput'
import { SimpleModal } from 'components/Models/Modal'
import React, {useState, useEffect, useContext} from 'react'
import toast from 'react-hot-toast'
import {importTokenApi} from 'services/tokens'
import { Store } from 'store'
import { updateTokens } from 'store/actions/workplace';

const ImportToken = ({show, setShow}) => {

    const {state, dispatch} = useContext(Store)
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        address: false,
        symbol: false,
        decimals: false
    })
    const [data, setData] = useState({
        address: '',
        symbol: '',
        decimals: ''
    })

    const importToken = async () => {
        if(!validate()) return;

        try{
            setLoading(true)
            const newToken = await importTokenApi({...data, decimals: parseInt(data.decimals)})
            if(state.workplace.tokens){
                updateTokens([...state.workplace.tokens, newToken], dispatch)
            }
            toast.success("Token successfully imported")
            setLoading(false)
            setShow(false)
            
            
        } catch(err){
            toast.error("Error occured")
            setLoading(false)
            console.log(err)
        }
    }

    const validate = () => {
        const newErrors = {address: false, symbol: false, decimals: false}
        if(!data.address) newErrors.address = true;
        if(!data.symbol) newErrors.symbol = true;
        if(!data.decimals) newErrors.decimals = true;
        setErrors(newErrors);
        return !newErrors.address && !newErrors.symbol && !newErrors.decimals;
    }

    useEffect(() => {
        setErrors({address: false, symbol: false, decimals: false})
    }, [data])

    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
            title= "Import Smart Contract"
        >
            <InputField 
                value = {data.symbol}
                setValue = {(e) => setData({...data, symbol: e})}
                label = "Nickname"
                error={errors.symbol}
                errorText = "Invalid nickname"
                setError = {(e) => setErrors({...errors, symbol: e})}
            />
            <InputField 
                value = {data.address}
                setValue = {(e) => setData({...data, address: e})}
                label = "Token Contract Address"
                error={errors.address}
                errorText = "Invalid token address"
                tooltip = "Find on polygon scan/ etherscan"
                setError = {(e) => setErrors({...errors, address: e})}
            />
            
            <Button 
                text = "Import Smart Contract"
                loading = {loading} 
                submitResponse = {() => importToken()} 
                className = "mt-3"
                block
            />
        </SimpleModal>
    )
}

export default ImportToken