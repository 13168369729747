import { EVENT_NAMES } from "config/constants";
import moment from 'moment'

const formatter = (data) => {


    const memberEvents = data.latestEventCounts.filter(item => item.name === EVENT_NAMES.MEMBER_CREATE || item.name === EVENT_NAMES.MEMBER_DELETE)
    const messageEvents = data.latestEventCounts.filter(item => item.name === EVENT_NAMES.MESSAGE_CREATE)
    const positiveMessageEvents = messageEvents.filter(item => item.sentiment === 'positive').reduce(accMessages, 0)
    const negativeMessageEvents = messageEvents.filter(item => item.sentiment === 'negative').reduce(accMessages, 0)

    const thisWeekDate = moment().startOf('week').format('YYYY-MM-DD')
    const lastWeekDate = moment().startOf('week').subtract(7, 'd').format('YYYY-MM-DD')

    const eventsAtStartOfWeek = data.lastTwoWeeksEventCounts.filter(item => moment(item.week).format('YYYY-MM-DD') === thisWeekDate || moment(item.week).format('YYYY-MM-DD') === lastWeekDate)
    const memberEventsAtStartOfWeek = eventsAtStartOfWeek.filter(item => item.name === EVENT_NAMES.MEMBER_CREATE || item.name === EVENT_NAMES.MEMBER_DELETE)
    const messageEventsAtStartOfWeek = eventsAtStartOfWeek.filter(item => item.name === EVENT_NAMES.MESSAGE_CREATE)

    const membersIncrease = memberEventsAtStartOfWeek.reduce(accMembers, 0)
    const currentMembers = memberEvents.reduce(accMembers, 0)
    const membersGrowth = currentMembers === membersIncrease ? 'NOT_ENOUGH' : parseInt(100*membersIncrease/(currentMembers-membersIncrease))
   
    const messagesIncrease = messageEventsAtStartOfWeek.reduce(accMessages, 0)
    const currentMessages = messageEvents.reduce(accMessages, 0)
    const messagesGrowth = currentMessages === messagesIncrease ? 'NOT_ENOUGH' : parseInt(100*messagesIncrease/(currentMessages-messagesIncrease))

    const thisWeekUniqueMembers = data.uniqueMemberCounts.filter(item => moment(item.week).format('YYYY-MM-DD') === thisWeekDate).reduce((acc, item) => acc + item.uniqueMembers, 0)
    const lastWeekUniqueMembers = data.uniqueMemberCounts.filter(item => moment(item.week).format('YYYY-MM-DD') === lastWeekDate).reduce((acc, item) => acc + item.uniqueMembers, 0)
    
    const thisWeekTotalMembers = currentMembers - memberEventsAtStartOfWeek.filter(item => moment(item.week).format('YYYY-MM-DD') === thisWeekDate).reduce(accMembers, 0)
    const lastWeekTotalMembers  = thisWeekTotalMembers - memberEventsAtStartOfWeek.filter(item => moment(item.week).format('YYYY-MM-DD') === lastWeekDate).reduce(accMembers, 0)
    
    const currentEngagement = thisWeekTotalMembers > 0 ? parseFloat(100*thisWeekUniqueMembers/currentMembers).toFixed(2) : 0
    const lastWeekEngagement = lastWeekTotalMembers > 0 ? parseInt(100*lastWeekUniqueMembers/thisWeekTotalMembers): 'NOT_ENOUGH'
    const engagementGrowth = lastWeekEngagement > 0 ? parseInt(100*(currentEngagement-lastWeekEngagement)/(lastWeekEngagement)) : 'NOT_ENOUGH'
    
    return sampleData({
        messages: currentMessages, 
        messageGrowth: messagesGrowth, 
        members: currentMembers,
        memberGrowth: membersGrowth,
        sentiment: parseInt(100*positiveMessageEvents/(positiveMessageEvents + negativeMessageEvents)) + '%', 
        engagement: typeof currentEngagement === 'string' ? currentEngagement+'%' : '0%',
        engagementGrowth: engagementGrowth,
        totalPositiveMessages: positiveMessageEvents,
        totalNegativeMessages: negativeMessageEvents
    })

}

export default formatter



const accMembers = (acc, item) => {
    if(item.name === EVENT_NAMES.MEMBER_CREATE) return acc + item.count;
    if(item.name === EVENT_NAMES.MEMBER_DELETE) return acc - item.count;
}


const accMessages = (acc, item) => acc + item.count

const sampleData = ({messages, messageGrowth, members, memberGrowth, sentiment, engagement, engagementGrowth, totalPositiveMessages, totalNegativeMessages}) => ([{
    title: 'Total members',
    value: members,
    description: 'one week change',
    trend: memberGrowth,
    icon: 'group-junior'
}, {
    title: 'Engagament rate',
    value: engagement,
    description: 'one week change',
    trend: engagementGrowth,
    icon: 'chat',
    help: 'This is the percentage of unique members who engaged at least once in last one week'
}, {
    title: 'off-chain activity',
    value: messages,
    description: 'one week change',
    trend: messageGrowth,
    icon: 'diamond',
    help: 'Total activity is defined as the sum of all messages & replies'
}, {
    title: 'Overall sentiment',
    value: sentiment,
    description: 'positive messages',
    icon: 'favorite-comment',
    progress: {
        totalPositive: totalPositiveMessages,
        totalNegative: totalNegativeMessages
    }
}])