import React, {useContext, useState, useEffect} from 'react'
import Dropdown, { BigDropdown } from 'components/Forms/Dropdown'
import {Store} from 'store'
import WhatsNewModel from './Models/WhatsNew'
import Search from './Search'
import User from './User'
import { Link } from 'react-router-dom'
import { ethers } from "ethers";
import {saveMetaMaskApi} from 'services/user'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {TooltipIconButton} from 'components/Others/Tooltip'
import { UserAvatar } from 'components/Avatar'
import { SwitchButton } from 'components/Forms/Switches'
import { useMoralis } from 'react-moralis'
import ConnectWallet from './ConnectWallet'

const Header = ({setOpen, setMiniMode}) => {

    const {state, dispatch} = useContext(Store);
    

    const [showWhatsNew, setShowWhatsNew] = useState(false)
    const [showWallet, setShowWallet] = useState(false)


    const [darkmode, setDarkmode] = useState(localStorage.getItem('darkmode') === 'true' ? true: false);
    const [account, setAccount] = useState({
        address: state.user.data?.wallets?.metamask?.address,
        balance: state.user.data?.wallets?.metamask?.balance,
    });


    useEffect(() => {
        localStorage.setItem('darkmode', darkmode)
        document.querySelector('html').setAttribute('dark-mode', darkmode ? 'true' : false);
        setColors()
    }, [darkmode])

    const toggleDarkmode = () => {
        setDarkmode(!darkmode);
    }

    const setColors = () => {
        if(darkmode){
            document.documentElement.style.setProperty('--background', '#e6f5ff');
            document.documentElement.style.setProperty('--border', '#b7b7b76e');
            document.documentElement.style.setProperty('--light-border', '#b7b7b7');
            document.documentElement.style.setProperty('--dark', '#000');
            document.documentElement.style.setProperty('--shadow', 'rgba(140, 152, 164, .075)');
            document.documentElement.style.setProperty('--shadow-md', 'rgba(140, 152, 164, .125)');
            document.documentElement.style.setProperty('--shadow-lg', 'rgba(140, 152, 164, .175)');
            document.documentElement.style.setProperty('--shadow-xl', 'rgba(140, 152, 164, .25)');
            
        } else{
            document.documentElement.style.setProperty('--background', 'rgb(247, 250, 252)');
            document.documentElement.style.setProperty('--light-border', 'rgba(231, 234, 243, .7)');
            document.documentElement.style.setProperty('--dark', '#131022');
            document.documentElement.style.setProperty('--shadow', 'rgba(140, 152, 164, .075)');
            document.documentElement.style.setProperty('--shadow-md', 'rgba(140, 152, 164, .125)');
            document.documentElement.style.setProperty('--shadow-lg', 'rgba(140, 152, 164, .175)');
            document.documentElement.style.setProperty('--shadow-xl', 'rgba(140, 152, 164, .25)');
        }
    }




    
    


    return (
        <>
        <header class="navbar navbar-expand-lg navbar-height navbar-flush navbar-container navbar-bordered shadow ml-sm-space" style = {{marginTop:'0.5rem', marginRight: '0.5rem'}}>
            <div class="navbar-nav-wrap">
                <div class="navbar-brand-wrapper">
                    <Logo />
                </div>
                <div class="navbar-nav-wrap-content-left">
                    <NavbarToggle setOpen = {setOpen} setMiniMode = {setMiniMode}/>
                    <div class="d-none d-md-block">
                        <Search />
                    </div>
                </div>
                <div class="navbar-nav-wrap-content-right">
                    <ul class="navbar-nav align-items-center flex-row">
                        <li class="nav-item d-none d-sm-inline-block">
                            <a class="btn btn-icon btn-ghost-secondary rounded-circle" onClick = {() => setShowWhatsNew(true)}>
                                <i class="tio-help-outlined"></i>
                            </a>
                        </li>
                        {/* <li class="nav-item d-none d-sm-inline-block">
                            <div class="hs-unfold">
                                <a class="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle" >
                                    <i class="tio-help-outlined "></i>
                                </a>
                            </div>
                        </li> */}
                            <OverlayTrigger placement="bottom" overlay={<Tooltip className='mt-1 ml-n1'>Darkmode</Tooltip>}>
                                <li class="nav-item d-none d-sm-inline-block mr-3">
                                    <SwitchButton
                                        value = {darkmode}
                                        setValue = {toggleDarkmode}
                                        size = "sm"
                                        label = {!darkmode ? <i class='tio-brightness-2'></i> : <i class='tio-moon'></i>}
                                    />
                                </li>
                            </OverlayTrigger>
                        <li class="nav-item d-none d-sm-inline-block">
                            {(account.address) ? <WalletConnected 
                                address = {account.address}
                                setAccount = {setAccount}
                            /> :
                            <OverlayTrigger placement="bottom" overlay = {<Tooltip>Connect your wallet and get ready for some exciting airdrops!</Tooltip>}>
                                <div class="btn btn-white btn-sm "onClick={() => setShowWallet(true)} >
                                    <span class="d-flex justify-content-center align-items-center">
                                        <i class='tio-wallet mr-2 text-primary'></i>
                                        Connect your wallet
                                    </span>
                                </div>
                            </OverlayTrigger>}
                        </li>
                        <li class="nav-item">
                            <BigDropdown
                                wrapperClassName = "navbar-dropdown-account-wrapper" 
                                popoverClassName = "navbar-dropdown-account"
                                popover = {<User />}
                                style = {{width: '16rem'}}
                            >
                                <UserAvatar 
                                    firstName = {state.user.data.firstName}
                                    avatar = {state.user.data.avatar}
                                    discordId = {state.user.data.discordId}
                                    active
                                />  
                            </BigDropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <WhatsNewModel show = {showWhatsNew} setShow = {setShowWhatsNew}/>
        <ConnectWallet show = {showWallet} setShow = {setShowWallet} account = {account} setAccount = {setAccount}/>
        </>
    )
}

export default Header





const Logo = () => {
    return (
        <Link to ="/app" class="navbar-brand" >
            <img class="navbar-brand-logo" src="/assets/svg/logos/logo.svg" alt="Logo" />
            <img class="navbar-brand-logo-mini" src="/assets/svg/logos/logo-short.svg" alt="Logo" />
        </Link>
    )
}



const NavbarToggle = ({setOpen}) => {
    return (
        <button type="button" class="js-navbar-vertical-aside-toggle-invoker close mr-3">
            <TooltipIconButton 
                icon = "tio-first-page navbar-vertical-aside-toggle-short-align"
                onClick = {() => {
                    setOpen(false)
                    localStorage.setItem('open', false)
                }}
                tooltip = "Toggle sidebar"
                position = "bottom"
            />
            <TooltipIconButton 
                icon = "tio-last-page navbar-vertical-aside-toggle-full-align"
                onClick = {() => {
                    setOpen(true)
                    localStorage.setItem('open', true)
                }}
                tooltip = "Toggle sidebar"
                position = "bottom"
            />
        </button>
    )
}





const WalletConnected = ({address, setAccount}) => {

    const {logout} = useMoralis()
    
    const disconnect = () => {
        logout()
        setAccount({address:null, balance:''})
    }

    return (
        <BigDropdown
            wrapperClassName = "" 
            popoverClassName = "navbar-dropdown-account"
            popover = {<div>
                <div class='dropdown-item' >
                    <span class='bold'>Address:</span>
                     <div>{address.slice(0, 25)}</div>
                     <div>{address.slice(25)}</div>
                </div>
                <div class="dropdown-divider"></div>
                <span class="dropdown-item pointer" onClick={disconnect}>
                    <span class="text-truncate text-danger pr-2" >Disconnect</span>
                </span>
            </div>}
            style = {{width: '16rem'}}
        >
            <div class="btn btn-white btn-sm " >
                <span class="d-flex justify-content-center align-items-center">
                    <img class="avatar avatar-xs mr-2" src="/assets/svg/brands/metamask.png" alt="" />
                    Wallet Connected
                </span>
                <span class="avatar-status avatar-sm-status avatar-status-success"></span>
            </div>
    </BigDropdown>
    )
}