import axios from 'axios'
import toast from 'react-hot-toast'
import config from 'utilis/config'
import {ERROR_TYPES} from 'config/constants'

const API_URL = config.serverLink + "/api/v1";

const axiosInstance =  axios.create({
    baseURL: API_URL,
    withCredentials: true,
}); 


axiosInstance.interceptors.response.use((response) => response, (error) => {
    console.log(error.response.data)
    if(error && error.response && error.response.data && error.response.data.error) {
        const message = error.response.data.error
        if(message === ERROR_TYPES.INVALID_TOKEN) throw error;
        if(
            message === ERROR_TYPES.INCORRECT_EMAIL || 
            message === ERROR_TYPES.INCORRECT_PASSWORD || 
            message === ERROR_TYPES.USER_ALREADY_EXISTS || 
            message === ERROR_TYPES.INVALID_TOKEN || 
            message === ERROR_TYPES.INVALID_HASH ||
            message === ERROR_TYPES.RESOURCE_ALREADY_EXISTS
        ) throw error;
        if(error.response.status > 399) notification(`Error: ${message}`)
        throw error;
    } else{
        throw error
    }
});


const notification = (title) => {
  toast.error(title);
}

export default axiosInstance;