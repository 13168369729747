import React from 'react'
import Chart from 'components/Charts'
import { hompageDAUChart, hompageConversionChart } from 'content/sampleData'

const SampleGraphs = () => {
  return (
    <div class='row mb-5'>
        <div class='col-md-6'>
            <div class='card'>
                <div class='card-body'>
                    <TimeSeries 
                        chartType = "line" 
                        timeseriesData = {hompageDAUChart}
                        title= "Daily acitve users"
                        description = "10 % increase over the last 30 days"
                    />
                </div>
            </div>
        </div>
        <div class='col-md-6'>
            <div class='card'>
                <div class='card-body'>
                    <TimeSeries 
                        chartType = "bar" 
                        timeseriesData = {hompageConversionChart}
                        title= "Conversion flow for wallets"
                        description = "8% of all users staked their profits "
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

export default SampleGraphs




const a = ['#377dff', '#00c9db', '#e7eaf3']


const TimeSeries = ({chartType, timeseriesData, title, description}) => {
    return (
        <div>
            <div class='mb-4'>
                <h4 className='mb-1'>{title}</h4>
                <p>{description}</p>
            </div>
            {(timeseriesData && timeseriesData.xAxis.length > 0) ? <Chart 
                type ={chartType}
                labels = {timeseriesData.xAxis}
                datasets =  {timeseriesData.yAxis}
                stepsize = {timeseriesData.stepsize}
                yprefix = {timeseriesData.yPrefix}
                customColors = {timeseriesData.customColors}
                customGradientColors = {timeseriesData.customGradientColors}
                gradient = {true}
                height = "18rem"
                id = "chartjs-as32"
                {...timeseriesData}
            /> : <div class='d-flex align-items-center justify-content-center' style = {{minHeight: '18rem'}}>
                <span class='spinner-border'></span>
            </div>}
            
        </div>
    )
}