const validations = {
    isEmail: (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(String(email).toLowerCase());
    },
    isPhone: (phone) => {
        const phone1 = phone + "";
        var cleanPhone = (phone1.split('-').join('').split(' ').join('').split('+').join(''));
        if(cleanPhone.length === 10 ) return true
        else if(cleanPhone.length === 11 && cleanPhone.slice(0, 1) === '0') return true;
        else if(cleanPhone.length === 12 && cleanPhone.slice(0, 2) === '91') return true;
        else return false;
    
    },
    isPassword: (pwd) => {
        return pwd.length > 7;
    },
    cleanPhone: (phone) => {
        const phone1 = phone + '';
        var cleanPhone = (phone1.split('-').join('').split(' ').join('').split('+').join(''));
        if(cleanPhone.length === 12) return cleanPhone.slice(2)
        if(cleanPhone.length === 10) return cleanPhone
        if(cleanPhone.length === 11) return cleanPhone.slice(1)
        return cleanPhone
    },
    isEmpty: (text) => {
        if(!text) return true
        if(typeof text === 'object') return text.length === 0
        else return !text || text ===undefined || text === '' || text.split(' ').join('') === ''
    },
    isEmptyArray: (text) => {
        return text.length === 0
    },
    isUrl: (text) => {
        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);
        if (text.match(regex)) return true;
        else return false;
    },
}


async function imageExists(imgUrl) {
    if (!imgUrl) {
        return false;
    }
    return new Promise(res => {
        const image = new Image();
        image.onload = () => res(true);
        image.onerror = () => res(false);
        image.src = imgUrl;
    });
}




export default validations 




