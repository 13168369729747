import React, {useState, useEffect} from 'react'
import { InputField, InputFieldRow } from 'components/Forms/TextInput'
import SelectField from 'components/Forms/Select';

const Evaluation = ({setStep, nextStep, prevStep, quest, setQuest}) => {

    
    const [metadata, setMetadata] = useState('');
    const [errors, setErrors] = useState({metadata: false})

    useEffect(() => {
        if(metadataInfo[quest.type]){
            setMetadata(quest.evaluation.metadata[metadataInfo[quest.type].metadataKey])
        }
    }, [])

    useEffect(() => {
        if(metadataInfo[quest.type]){
            setQuest({...quest, evaluation: {...quest.evaluation, metadata: {
                [metadataInfo[quest.type].metadataKey]: metadata
            }}})
        }
    }, [metadata])


    const editStartQuest = (label, value) => {
        setQuest(prev => ({
            ...prev, 
            startQuest: {
                type: 'DISCORD_COMMAND',
                metadata: {
                    ...prev.startQuest.metadata,
                    [label]: value
                }
            }
        }))
    }


    const handleNext = () => {
        const newErrors = {...errors}
        if(!metadata){
            newErrors.metadata = true;
        }
        setErrors(newErrors)
        if(!newErrors.metadata){
            setStep(nextStep)
        }
    }

    useEffect(() => {
        setErrors({metadata: false})
    }, [metadata])

    return (
        <div id="addUserStepProfile" class="card card-lg active" >
            <div class="card-body">
                {/* <div class="alert alert-soft-secondary mb-4" role="alert">
                    You can select a custom audience to recieve this aidrop. You can either choose by their wallet, member or activity attributes. 
                </div> */}
                {(metadataInfo[quest.type] && !metadataInfo[quest.type].options) && <InputField 
                    label = {metadataInfo[quest.type].label}
                    columnSize={4}
                    value = {metadata}
                    setValue = {(e) => setMetadata(e)}
                    placeholder = {metadataInfo[quest.type].placeholder}
                    description={metadataInfo[quest.type].description}
                    errorText = {metadataInfo[quest.type].errorText}
                    labelClass="bold"
                    error = {errors.metadata}
                    setError = {e => setErrors(prev => ({...prev, metadata:e}))}
                />}
                {(metadataInfo[quest.type] &&  metadataInfo[quest.type].options) && <SelectField 
                    label = {metadataInfo[quest.type].label}
                    columnSize={4}
                    value = {metadata}
                    setValue = {(e) => setMetadata(e)}
                    placeholder = {metadataInfo[quest.type].placeholder}
                    description={metadataInfo[quest.type].description}
                    errorText = {metadataInfo[quest.type].errorText}
                    labelClass="bold"
                    error = {errors.metadata}
                    setError = {e => setErrors(prev => ({...prev, metadata:e}))}
                    options = {metadataInfo[quest.type].options}
                />}
                <div className='form-group mb-3 mt-6'>
                    <label class='h3'>Quest start url</label>
                    <p className='mt-n2 fs-sm mb-0'>{metadataInfo[quest.type].description}</p>
                    <p className='fs-sm font-italic'>(You can leave this empty if you dont want this)</p>
                </div>
                <div class='row'>
                    {(metadataInfo[quest.type] && metadataInfo[quest.type].startQuest.metadata) && metadataInfo[quest.type].startQuest.metadata.map(item => <div class='col-12 col-lg-12'>
                        <InputField 
                            value = {quest.startQuest.metadata[item.key]}
                            setValue = {(e) => editStartQuest(item.key, e)}
                            label = {item.label}
                            placeholder = {item.placeholder}
                            description={item.description}
                            labelClass="bold"
                        />
                    </div>)}
                </div>
                
            </div>
            <div class="card-footer d-flex justify-content-end align-items-center">
                <button type="button" class="btn btn-soft-primary mr-3" onClick={() => setStep(prevStep)}>
                    <i class="tio-chevron-left"></i> Back
                </button>
                <div class='ml-auto'>
                <button type="button" class="btn btn-primary" onClick={() => handleNext()}>
                    Next <i class="tio-chevron-right"></i>
                </button>
                </div>
            </div>
        </div>
  )
}

export default Evaluation





const metadataInfo = {
    'QUEST::TWITTER::FOLLOW': {
        metadataKey: 'account',
        placeholder: '',
        label: 'Twitter account',
        errorText: 'Please enter the twitter account to be followed',
        description: 'Please enter the twitter account to be followed',
        
    },
    'QUEST::TWITTER::RETWEET': {
        metadataKey: 'tweet',
        placeholder: '',
        label: 'Tweet to be retweeted',
        errorText: 'Please enter the tweet link to be retweeted',
        description: 'Please enter the tweet link to be retweeted',
        startQuest: {
            description: 'You can add a link here from where you want your member to start the quest from. This could be discord invite link in case you are starting a Join Discord Quest.',
            metadata: [{
                key: 'label',
                label: 'Enter the button label',
                description: 'Please enter a slash command name, it should start with a / and be unique',
                placeholder: 'Retweet using this ink',
            }, {
                key: 'link',
                label: 'Enter the button link',
                placeholder: 'https://twitter.com/intent/retweet?tweet_id=1234',
            }]
        }
    },
    'QUEST::WALLET::CONNECT': {
        metadataKey: 'walletChain',
        placeholder: '',
        label: 'Allowed Chains',
        errorText: 'Please select chains you want to allow',
        description: 'Please select chains you want to allow for users to connect their wallet from',
        options: [{
            value: 'ethereum',
            label: 'Ethereum'
        }, {
            value: 'polygon',
            label: 'Polygon'
        }, {
            value: 'solana',
            label: 'Solana'
        }],
        isMultiSelect: true,
        startQuest: {
            description: 'You can add a link here from where you want your member to start thq quest from. This could be discord invite link in case you are starting a Join Discord Quest.',
            metadata: [{
                key: 'commandName',
                label: 'Enter the command name',
                description: 'Please enter a slash command name, it should start with a / and be unique',
                placeholder: '/wallet-connect',
            }, {
                key: 'commandDescription',
                label: 'Enter the command description',
                placeholder: 'Command to link your wallet to get exciting rewards',
            }]
        }
    },

}