import { Button } from 'components/Forms/Buttons';
import { SimpleModal } from 'components/Models/Modal'
import React, {useState} from 'react'
import { useMoralis, useWeb3Contract } from "react-moralis";
// import MultiSendJson from 'contracts/Multisend.json'
// import Mytoken from 'contracts/Mytoken.json'

let tokenAddress = '0x3b46c6F44D875Dc7164056B3443bbd2eFda434B5'
let toAddress = ['0x3200A07675C3623AE9C9f1584Aead9CCFDd30F0F', '0x8de58c195CBc6CFE21745341288C6bEcAdcb2Ca9']
let multisendAddress = '0x4a322119180c3Ee33852d13a9F64E16b44D04FCA'

const LaunchAirdrop = ({show, setShow, setToken}) => {

    const [loading, setLoading] = useState(false);
    const { enableWeb3, isWeb3Enabled } = useMoralis();
    // const {runContractFunction: approveAllowance, error: allowanceError} = useWeb3Contract({
    //     abi: Mytoken.abi,
    //     contractAddress: tokenAddress,
    //     functionName: 'approve',
    //     params: {
    //         spender: multisendAddress, 
    //         amount: 3000000000000000, 
    //     }
    // })
    const {runContractFunction: sendTransaction, transactionError} = useWeb3Contract({
        abi: {},
        contractAddress: multisendAddress,
        functionName: 'multisendToken',
        params: {
            token: tokenAddress, 
            recipients: toAddress, 
            values: [1000000000000000, 1000000000000000], 
        }
    })
 
    const sendTokens = async () => {
        try{
            setLoading(true)
            // await approveAllowance()
            await sendTransaction()
            setLoading(false)
        }catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    const login = async () => {
        if (!isAuthenticated) {
            await enableWeb3({signingMessage: "Log in using Moralis" })
        }
      }
  

    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
            title = "Complete the steps to generate a unique reward link"
        >

            <Button 
                loading = {loading}
                text = "Approve"
                submitResponse={() => enableWeb3()}
                block
            />
            <Button 
                loading = {loading}
                text = "Send tokens"
                submitResponse={() => sendTokens()}
                block
            />


        </SimpleModal>
    )
}

export default LaunchAirdrop