import React, {useState, useContext, useEffect} from 'react'
import EditMemberModel from './EditMember'
import {editMemberApi} from 'services/members'
import toaster from 'react-hot-toast'

export const ProfileCover = ({}) => {
    return (
        <div class="profile-cover">
            <div class="profile-cover-img-wrapper">
                <img class="profile-cover-img" src="/assets/img/1920x400/img1.jpg" alt="" />
            </div>
        </div>
    )
}


export const ProfileHeader = ({member, setMember}) => {

    const [showEdit, setShowEdit] = useState(false);

    const addAsTeamMember = async () => {
        try{
            // await editMemberApi(member._id, {
            //     'adminInputs.isTeamMember': !member.adminInputs.isTeamMember
            // })
            // !member.adminInputs.isTeamMember ? toaster.success("Member marked as your team mate") : toaster.success("Member unmarked as your team mate")
            
            // setMember(prev => ({
            //     ...prev,
            //     adminInputs: {
            //         ...prev.adminInputs,
            //         isTeamMember: !prev.adminInputs.isTeamMember
            //     }
            // }))
            
        } catch (err) {
            toaster.error("Some error occured, please refresh and try again")
            console.log(err)
        }
    }

    return (
        <>
        <div class = 'row'>
            <div class = 'col-3 text-center'>
                <div class="avatar avatar-xxl avatar-circle avatar-border-lg avatar-soft-primary profile-cover-avatar">
                    <img class="avatar-img" src="/assets/avatars/01.png" /> 
                    {/* {
                        member.metadata.avatar ? <img class="avatar-img" src={member.metadata.avatar} alt={member.metadata.username} /> : 
                        <span class='avatar-initials'>{member.metadata.username[0].toUpperCase()}</span>
                    } */}
                </div>
            </div>
        </div>
        <div class="row ">
            <div class ='col'>
                <h1 class="page-header-title text-truncate-xl">
                    @sambhavjain.eth <i class="tio-verified tio-lg text-primary"></i>
                </h1>
                {/* <h1 class="page-header-title text-truncate-xl">
                    @{member.metadata.username} {!member.adminInputs.isTeamMember && <i class="tio-verified tio-lg text-primary"></i>}
                </h1> */}
                {/* {member.adminInputs.isTeamMember && <span class="badge badge-soft-primary mt-n3 mb-3">team member</span>} */}
                <ul class="list-inline list-inline-m-1">
                    {/* {member.adminInputs.organization && <li class="list-inline-item">
                        <i class="tio-city mr-1"></i>
                        <span>{member.adminInputs.organization.name}</span>
                    </li>} */}
                    <li class="list-inline-item">
                        <i class="tio-date-range mr-1"></i>
                        <span>Joined 5 days ago</span>
                    </li>
                </ul>
            </div>
            <div class='col-auto'>
                <div class='btn btn-soft-secondary btn-sm mr-2' onClick={() => setShowEdit(true)}>
                    <i class='tio-edit mr-2'></i>
                    Edit
                </div>
                <div class='btn btn-soft-secondary btn-sm' onClick={() => addAsTeamMember()}>
                    <i class='tio-user-add mr-2'></i> Add as a team member
                    {/* {!member.adminInputs.isTeamMember ? 'Add as team member' : 'Remove from team'} */}
                </div>
            </div>
        </div>
        {/* <EditMemberModel 
            show = {showEdit}
            setShow = {setShowEdit}
            member = {member}
            setMember = {setMember}
        /> */}
        </>
    )
}

