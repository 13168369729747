import React, {useState, useEffect, useRef, useContext} from 'react'
import {Link, useHistory} from 'react-router-dom'
import AnimateHeight from 'react-animate-height'
import { getAllSourcesApi } from 'services/sources'
import ChangeGuilds from './ChangeGuilds'
import {Store} from 'store'
import { saveSources } from 'store/actions/community'
import toast from 'react-hot-toast'

const Navbar = ({setOpen, type, open}) => {

    const {state, dispatch} = useContext(Store)
    const [show, setShow] = useState(findActive());
    const [showIntegrationProcess, setShowIntegrationProcess] = useState()
    const timer = useRef()

    useEffect(() => {
        setShow(findActive())
    }, [window.location.pathname])

    useEffect(() => {
        if(state.community.sources){
            checkForIntegrationStatus(state.community.sources)
        } else getAllSources();
    }, [state.community.sources])

    const getAllSources = async () => {
        try{
            const allSources_ = await getAllSourcesApi();
            saveSources(allSources_, dispatch)
        } catch(err){
            console.log(err)
        }
    }

    const checkForIntegrationStatus = (sources) => {
        const index = sources.findIndex(item => item.databackup?.status === 'STARTED');
        if(index !== -1){
            setShowIntegrationProcess({
                ...sources[index]
            })
            if(timer.current) clearInterval(timer.current);
            timer.current = setInterval(() => {
                getAllSources()
            }, 5000)
        } else {
            if(timer.current) clearInterval(timer.current);
            if(showIntegrationProcess){
                setTimeout(() => {
                    setShowIntegrationProcess();
                    toast.success("Your discord server has been added! Please refresh to get the new data")
                }, 2000)
            } else{
                setShowIntegrationProcess()
            }
        }
    }

    
  

    return (   
        <aside class="navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-lg navbar-bordered default navbar-vertical-aside-initialized">
            <div class="navbar-vertical-container ">
                <div class="d-flex flex-column h-100">
                    <div class="navbar-brand-wrapper justify-content-between">
                        <Logo />
                        <button type="button" class="navbar-vertical-aside-toggle btn btn-icon btn-xs btn-ghost-dark" onClick = {() => setOpen(false)}>
                            <i class="tio-clear tio-lg"></i>
                        </button>
                    </div>
                    <div class="navbar-vertical-content">
                        {/* {open && <div class='mx-3 my-2'>
                            <ChangeGuilds />
                        </div>} */}
                        <ul class="navbar-nav navbar-nav-lg nav-tabs">
                            {navMap.map(item => {
                                if(item.type === 'nav-item') return <NavItem {...item}/>
                                if(item.type === 'dropdown-item') return <DropdownItem {...item} show = {show} setShow = {setShow} type= {type}/>
                                if(item.type === 'seperator') return <Seperator />
                                if(item.type === 'heading') return <Heading {...item}/>
                            })}
                        </ul>
                    </div>
                    {/* {(open && !showIntegrationProcess) && <div class='mt-auto'>
                        <img class="avatar avatar-xxl " src="/assets/img/bee.png" alt="" />
                    </div>} */}
                    {(showIntegrationProcess) && <div class='mt-auto'>
                        <div class='card'>
                            <div class='card-body'>
                                <div class='d-flex align-items-center'>
                                    <span class='spinner-border spinner-border-sm mr-2'></span>
                                    <div>{showIntegrationProcess?.name} data backup in progress</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </aside>
    )
}

export default Navbar;




const navMap = [{
    type: 'nav-item',
    label: 'Home',
    path: '/app',
    icon: 'tio-home-vs-1-outlined'
}, 
{
    type: 'dropdown-item',
    label: 'Members',
    path: '/app/members/token-holders',
    icon: 'tio-user-big',
    id: 'members',
    subMenuItems: [{
        path: '/app/members/token-holders',
        label: 'Connected Wallets'
    }, {
        path: '/app/members/discord',
        label: 'Community Members'
    }, 
    // {
    //     path: '/app/members/twitter',
    //     label: 'Twitter Followers'
    // }
]
}, 
{
    type: 'nav-item',
    label: 'ID Connect',
    path: '/app/id-connect',
    icon: 'tio-flag'
}, 
{
    type: 'seperator',
}, 
// {
//     type: 'heading',
//     label: 'Analytics',
//     icon: 'tio-brightness-1',
// }, 
// {
//     type: 'nav-item',
//     label: 'Segments',
//     path: '/app/segments',
//     icon: 'tio-users-switch'
// }, 
{
    type: 'nav-item',
    label: 'Analytics',
    path: '/app/analyze/wallet',
    icon: 'tio-poll'
}, 
// {
//     type: 'nav-item',
//     label: 'Wallet Analytics',
//     path: '/app/analyze/wallet',
//     icon: 'tio-wallet'
// }, 
// {
//     type: 'dropdown-item',
//     label: 'Report',
//     path: '/app/report',
//     icon: 'tio-chart-pie-2',
//     id: 'report',
//     subMenuItems: [{
//         path: '/app/report/saved',
//         label: 'Saved Reports'
//     }]
// },
{
    type: 'seperator',
}, 
// {
//     type: 'heading',
//     label: 'Engagement',
//     icon: 'tio-brightness-1',
// },
{
    type: 'nav-item',
    label: 'Segments',
    path: '/app/segments',
    icon: 'tio-group-equal'
}, 
{
    type: 'nav-item',
    label: 'Campaigns',
    icon: 'tio-send',
    path: '/app/campaigns',
},
{
    type: 'nav-item',
    label: 'Journeys',
    icon: 'tio-swap-vs',
    path: '/app/journeys',
}, 
{
    type: 'seperator',
}, 
// {
//     type: 'heading',
//     label: 'Account',
//     icon: 'tio-account',
// }, 
{
    type: 'nav-item',
    label: 'Integrations',
    path: '/app/account/data-sources/add',
    icon: 'tio-memory-chip'
}, 
// {
//     type: 'nav-item',
//     label: 'Tokens',
//     path: '/app/account/tokens',
//     icon: 'tio-coin'
// }, 
{
    type: 'nav-item',
    label: 'Account',
    id: 'account',
    path: '/app/account/settings',
    icon: 'tio-settings',
}]









const Logo = () => {
    return (
        <a class="navbar-brand" href="./index.html" aria-label="Front">
            <img class="navbar-brand-logo" src="/assets/svg/logos/logo.svg" style={{
                width: '200px'
            }}/>
            <img class="navbar-brand-logo-mini" src="/assets/svg/logos/logo-short.svg" alt="Logo" />
        </a>
    )
}


const Seperator = () => {
    return(
        <li class="nav-item">
            <div class="nav-divider"></div>
        </li>
    )
}




const Heading = ({label, icon}) => {
    return (
        <li class="nav-item">
            <small class="nav-subtitle">{label}</small>
            <small class={`{icon} nav-subtitle-replacer`}></small>
        </li>
    )
}



const DropdownItem = ({type, show, setShow, id, label, subMenuItems, icon, path}) => {


    const history = useHistory()
    const ref = useRef()
    const [height, setHeight] = useState(ref.current ? ref.current.offsetTop : '')


    useEffect(() => {
        if(ref && ref.current) setHeight(ref.current.offsetTop)
    }, [type, show])

    return (
        <li 
            class={`navbar-vertical-aside-has-menu ${(show === id) ? 'show' : ''}`} 
            onClick = {() => {
                setShow(prev => prev === id ? '' : id)
            }}
            ref = {ref}
        >
            <span class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle px-3 mx-3" onClick={() => {
                if(path) {
                    history.push(path)
                }
            }}>
                <i class={`${icon} nav-icon`}></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{label}</span>
            </span>
            {type === 'miniView' ? <SubMenuSm top = {height} menuItems = {subMenuItems}/> : 
            <AnimateHeight height = {(show === id) ? "auto" : "0"} duration = {300}>
                <SubMenu menuItems = {subMenuItems}/>
            </AnimateHeight> }
        </li>
    )
}





const SubMenu = ({menuItems}) => {
    return(
        <ul class="js-navbar-vertical-aside-submenu nav nav-sub" >
            {menuItems.map((item, index) => <li class="nav-item" key = {index}>
                <Link class={`nav-link px-3 ml-6 mr-3 ${window.location.pathname.includes(item.path) ? 'active' : ''}`} to = {item.path}>
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="text-truncate">{item.label}</span>
                </Link>
            </li>)}
        </ul>
    )
}


const SubMenuSm = ({menuItems, top}) => {

    return(
        <ul 
            class="js-navbar-vertical-aside-submenu nav nav-sub" 
            style={{ 
                top: top,
                transition: 'unset',
            }}
        >
            {menuItems.map((item, index) => <li class="nav-item" key = {index}>
                <Link class={`nav-link ${window.location.pathname.includes(item.path) ? 'active' : ''}`} to = {item.path}>
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="text-truncate">{item.label}</span>
                </Link>
            </li>)}
        </ul>
    )
}



const NavItem = ({label, path, icon}) => {
    return (
        <li class="nav-item ">
            <Link class={`nav-link px-3 mx-3 ${path === window.location.pathname ? 'active' : ''}`} to = {path}>
                <i class={`${icon} nav-icon `}></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{label}</span>
            </Link>
        </li>
    )
}


const findActive = () => {
    const location = window.location.pathname;
    for(let i=0; i<navMap.length; i++){
        if(navMap[i].type === 'dropdown-item' && location !== '/app'){
            if(navMap[i].subMenuItems.filter(item => item.path.includes(location)).length > 0) {
                return (navMap[i].id);
            }
        }
    }
    return ''
}