import React from 'react'
import { Link } from 'react-router-dom'

const Table = () => {
    return (
        <div class="table-responsive">
            <table class="table table-striped table-borderless table-lg table-thead-bordered table-nowrap table-align-middle card-table">
                <thead class="thead-light">
                    <tr class=''>
                        <th class="col">Campaign</th>
                        <th class="col-auto table-column-right-aligned">Status</th>
                        <th class="col-auto table-column-right-aligned">Channel</th>
                        <th class="col-auto table-column-right-aligned"># Members</th>
                        <th class="col-auto table-column-right-aligned">Conversion</th>
                    </tr>
                </thead>
                <tbody>
                    {journeys.map(item => <tr>
                        <td>
                            <div class="">
                                <Link to = "/" class="h5 mb-0 mt-0">{item.name}</Link>
                                <div class='small'>{item.description}</div>
                                <span class='badge badge-soft-primary mt-1'>{item.type}</span>
                            </div>
                        </td>
                        <td class="col-auto table-column-right-aligned">
                            <span class={`badge badge-${item.status === 'completed' ? 'success' : (item.status === 'draft' ? 'secondary': 'primary')}`}>
                                {item.status}
                            </span>
                        </td>
                        <td class="col-auto table-column-right-aligned">
                            <span class=''>{item.channel}</span>
                        </td>
                        <td class="col-auto table-column-right-aligned">{item.engaged}</td>
                        <td class="col-auto table-column-right-aligned">{item.conversion}{item.conversion &&'%'}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}

export default Table


const journeys = [
    {
        name: "NFT NYC Appreciation",
        description: "Rewarding users who attended NFT NYC 22’ with POAP ",
        type: 'Token Airdrop',
        channel: 'Ethereum',
        token: 'POAP',
        engaged: 121,
        conversion: 14,
        status: 'live'
    },
    {
        name: "Newbie Airdrop",
        description: "Airdroping matic to new users who joined last week for testnet launch",
        type: 'NFT Airdrop',
        channel: 'Polygon',
        token: 'MATIC',
        engaged: '',
        conversion: '',
        status: 'draft'
    },
    {
        name: "Product usage nudge",
        description: "Gentle reminder to product users about upcoming feature launches ",
        type: 'Message',
        channel: 'EPNS',
        token: '',
        engaged: 82,
        conversion: 10,
        status: 'completed'
    },
]