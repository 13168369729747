import React, {useEffect, useState} from 'react'
import { PageHeader } from 'components/Text'
import { getAllSourcesApi } from 'services/sources';
import { Button, HrefSmButton } from 'components/Forms/Buttons';
import moment from 'moment';
import config from 'utilis/config';
import AddIntegration from './Components/AddIntegration';
import UploadWalletAddresses from './Components/UploadWalletAddresses';
import { getUploadsApi } from 'services/workplace';
import {Link} from 'react-router-dom'


const Integrations = (props) => {

    const [showAdd, setShowAdd] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [sources, setSources] = useState([]);
    const [uploads, setUploads] = useState([]);

    useEffect(() => {
        getAllSources()
    }, [])

     
    const getAllSources = async () => {
        try{
            
            const allSources = await getAllSourcesApi();
            const allUploads = await getUploadsApi();
            setSources([...allSources, ...allUploads.map(item => ({
                ...item,
                name: item.name,
                icon: 'tio-upload', 
                type: 'Custom UPLOAD', 
            }))])

        } catch(err){
            console.log(err)
        }
    }

    return (
        <>
            <PageHeader 
                title = "Data Sources"
                description="Intract is most useful when you can see everything your customers are doing — that includes all the tools you use outside of Intract! "
                button = {
                    <Link to ="/app/account/data-sources/add" class='btn btn-primary'>Add Data Source</Link>
                }
            />
            <div class='row row-cols-1'>
                {sources.map(item => <IntegrationItem {...item}/>)}
            </div>
            <AddIntegration 
                show = {showAdd}
                setShow = {setShowAdd}
                setShowUpload = {setShowUpload}
            />
            <UploadWalletAddresses 
                show = {showUpload}
                setShow = {setShowUpload}
            />
        </>
    )
}

export default Integrations



const IntegrationItem = ({name, icon, type, isActive, integrationId, createdAt}) => {
    return (
        <div class="col mb-3 mb-lg-5">
            <div class="card card-body">
                <div class="media">
                    <div class="avatar avatar-md avatar-circle avatar-soft-primary mr-3">
                        {icon.includes('tio') ? <span class='avatar-initials '><span class='display-4'><i class={icon}></i></span></span>
                        :<img class="avatar-img " src={(icon && !icon.includes('null') ? icon : '/assets/svg/brands/discord.svg')} alt="" />}
                    </div>
                    <div class="media-body">
                        <div class="row align-items-sm-center">
                            <div class="col">
                                <h3 class="mb-1">{name}</h3>
                            </div>
                            <div class="col-md-3 d-none d-md-flex justify-content-md-end ml-n3">
                            <span class="badge font-size-xs badge-soft-primary p-2 mb-2">{type.toLowerCase()}</span>
                            </div>
                        </div>
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <span class="font-size-sm">Created on: </span>
                                <span class="font-weight-bold text-dark">{moment(createdAt).format('Do MMM YY')}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}