import { SearchHeader } from 'components/Table'
import { PageHeader } from 'components/Text'
import React from 'react'
import {Link} from 'react-router-dom'
import Table from './components/Table'
import Templates from './components/Templates'


const Journeys = () => {

    const [showTemplates, setShowTemplates] = React.useState(false)
    const [searchInput, setSearchInput] = React.useState('')


    return (
        <div>
            <PageHeader 
				title="Journeys" 
                description = "Drive up revenue and engagement with key automated user journeys"
				button = {<div onClick={() => setShowTemplates(true)} class='btn btn-primary'>Create a new journey</div>}
			/>
            <div class='card'>
                <SearchHeader 
                    searchInput = {searchInput} 
                    setSearchInput = {setSearchInput}
                    searchPlaceholder  = "Search journeys"
                />
                <Table />
            </div>
            <Templates
                show = {showTemplates}
                setShow = {setShowTemplates}
            />
        </div>
  )
}

export default Journeys