import React, {useRef, useEffect, useState} from 'react'
import './chartjs/data'


const Chart = (props) => {
    
    const chartRef = useRef();
    const canvasRef = useRef();

    useEffect(() => {
        if(canvasRef && canvasRef.current){
            const ele = canvasRef.current;
            var {chart, options} = window.HSCore.init(ele);
            chartRef.current = chart
        }
        return () => {
            chartRef && chartRef.current && chartRef.current.destroy()
        }
    }, [props.type, canvasRef]);

    
    useEffect(() => {
        updateChartData()
    }, [props]);


    const updateChartData = () => {
        if(chartRef && chartRef.current){
            chartRef.current.data.datasets.forEach(function(dataset, key) {
                dataset.data = props.datasets[key];
            });
            chartRef.current.data.labels = props.labels;
            chartRef.current.update()
        }
    }

    return (
        <>
            <div class={props.type === 'doughnut' ? "chartjs-doughnut-custom" : "chartjs-custom"} style={{ height: props.height }}>
                <canvas class="js-chart" id = {props.id} data-hs-chartjs-options={JSON.stringify(chartConfigMaker({...props}))} ref = {canvasRef}>
                </canvas>
                {props.customStat && props.customStat}
            </div>
        </>
    );
};

export default Chart;



const chartConfigMaker = ({type, labels, datasets, barWidth, stacked, gradient, yprefix, stepsize, ypostfix, hideXAxis, customColors, customGradientColors}) => {

    const colors = {
        primary: customColors ? customColors : ['#377dff', '#00c9db', '#e7eaf3'],
        gradient: customGradientColors ? customGradientColors : ['rgba(55, 125, 255, .5)', 'rgba(0, 201, 219, .5)']
    }

    const data = {
        type,
        data: {
            "labels": labels,
            "datasets": datasets.map((dataset, key) => ({
                "data": dataset,
            }))
        },
        options: {
            "gradientPosition": {"y1": 300}, // gradientPosition
            "cornerRadius": 40, // cornerRadius
            "tooltips": {
                "prefix": yprefix,
                "hasIndicator": true,
                "mode": "index",
                "intersect": false,
                "postfix": ypostfix, 
                "lineWithLineColor": "rgba(19, 33, 68, 0.075)"
            },
            "hover": {
                "mode": "nearest",
                "intersect": true
            },
            "scales": {
                "yAxes": [{
                    "gridLines": {
                        "color": "#e7eaf3",
                        "drawBorder": false,
                        "zeroLineColor": "#e7eaf3"
                    },
                    "ticks": {
                        "beginAtZero": true,
                        // "stepSize": stepsize,
                        "fontSize": 12,
                        "fontColor": "#97a4af",
                        "fontFamily": "Open Sans, sans-serif",
                        "padding": 10,
                        "postfix": ypostfix
                    }
                }],
                "xAxes": [{
                    "gridLines": {
                        "display": false,
                        "drawBorder": false
                      },
                    "ticks": {
                        "fontSize": 12,
                        "fontColor": "#97a4af",
                        "fontFamily": "Open Sans, sans-serif",
                        "padding": 5
                    },
                    "categoryPercentage": 0.5, //this and bottom only for bar graph
                    "maxBarThickness": barWidth ? barWidth : 10
                }]
            }
        }
    }
    if(hideXAxis){
        data.options.scales.xAxes = [{
            display: false
        }]
    }
    if(type === 'bar'){
        data.data.datasets = data.data.datasets.map((item, index) => ({
            ...item,
            "backgroundColor": colors.primary[index],
            "hoverBackgroundColor": colors.primary[index],
            "borderColor": colors.primary[index],
            "backgroundColor": colors.primary[index],
        }))
    }
    if(type === 'line'){
        data.options.tooltips.lineMode = true;
        data.data.datasets = data.data.datasets.map((item, index) => ({
            ...item,
            "borderColor": colors.primary[index],
            "borderWidth": 2,
            "pointRadius": 0,
            "hoverBorderColor": colors.primary[index],
            "pointBackgroundColor": colors.primary[index],
            "pointBorderColor": "#fff",
            "pointHoverRadius": 0,
            "backgroundColor": gradient ? [colors.gradient[index], "rgba(255, 255, 255, .2)"] : "transparent",
        }))
    }
    if(type === 'doughnut'){
        data.data.datasets = data.data.datasets.map((item, index) => ({
            ...item,
            "backgroundColor": ['#377dff', '#00c9db', '#ff6969'],
            "borderWidth": 1,
            "hoverBorderColor": "#fff"
        }))
        data.options.cutoutPercentage = 70;
        delete data.options.scales

    }
    if(stacked){
        data.type = 'bar'
        data.options.scales.xAxes[0].stacked = true
        data.options.scales.yAxes[0].stacked = true
        data.options.scales.xAxes[0].maxBarThickness = 40
        data.data.datasets = data.data.datasets.map((item, index) => ({
            ...item,
            "backgroundColor": colors.primary[index],
            "hoverBackgroundColor": colors.primary[index],
            "borderColor": colors.primary[index],
            "backgroundColor": colors.primary[index],
        }))
    }
    return data;
}

