import React, {useState, useEffect, useContext} from 'react'
import { Button } from 'components/Forms/Buttons'
import { SwitchButton, SwitchRow } from 'components/Forms/Switches'
import { PageHeader } from 'components/Text'
import Dropdown from 'components/Forms/Dropdown'
import ShowAnnouncement from './components/ShowAnnouncement'
import { useHistory } from 'react-router-dom'
import { Store } from 'store'
import { updateQuests } from 'store/actions/workplace'
import {getAllQuestsApi, toggleQuestApi} from 'services/quests'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const IDConnect = () => {


    const {state, dispatch} = useContext(Store)
    const [quests, seQuests] = useState([]);

    useEffect(() => {
       getAllQuests()
    }, [])

    useEffect(() => {
        if(state.workplace.quests){
            seQuests(state.workplace.quests.map(item => formatQuest(item)))
        } 
    }, [state.workplace.quests])

    const getAllQuests = async () => {
        try{
            
            const allQuests = await getAllQuestsApi();
            updateQuests(allQuests, dispatch)

        } catch(err){
            console.log(err)
        }
    }


    const formatQuest = (quest) => {
        return {
            ...quest,
            img: quest.type === "QUEST::WALLET::CONNECT" ? 'discord' : 'gmail',
            icon: quest.type === "QUEST::WALLET::CONNECT" ? 'tio-message' : 'tio-email',
            title: quest.title,
            description: quest.type === "QUEST::WALLET::CONNECT" ?  'Reward your discord members for linking their wallet addresses. This will help identify which discord members hold tokens.' : 'Enable your discord members to subscribe for important project alerts & updates right in their inbox.',
            reward: quest.reward.xpValue,
            discordCommand: quest?.startQuest?.metadata?.commandName,
            flowType: quest?.startQuest?.metadata?.flowType
        }
    }

    return (
        <div>
            <PageHeader 
                title = "ID Connect: Track member identity across platforms"
                description="Members can link wallets & accounts to get credit for their contributions"
            />
            <div class='row'>
                {quests.map(item => <div class='col-lg-4'>
                    <CardItem data = {item}/>
                </div>)}
                {comingSoon.map(item => <div class='col-lg-4'>
                    <CardItem data = {item}/>
                </div>)}
            </div>
        </div>
    )
}

export default IDConnect




const CardItem = ({data}) => {

    const history = useHistory()
    const {state, dispatch} = useContext(Store);
    const [showEdit, setShowEdit] = useState(false);
    const [showAnnouncement, setShowAnnouncement] = useState(false);
    const [active, setActive] = useState(data.status === 'STARTED');
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setActive(data.status === 'STARTED')
    }, [data])

    const toggleCampaignStatus = async (e)  => {
        try{
            setLoading(true)
            await toggleQuestApi(data._id, e)
            setActive(e)
            setLoading(false)
            if(state.workplace.quests && quest_){
                const allQuests = [...state.workplace.quests]
                const index = allQuests.findIndex(item => item._id === quest_._id)
                if(index !== -1){
                    allQuests[index].status = e ?'STARTED': 'PAUSED'
                }
                updateQuests(allQuests, dispatch)
            }
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    return(
        <>
        <div class={`card h-100 ${data.template ? 'card-dashed' : 'card-hover-shadow'}`}>
            <div class={`card-body  ${data.template ? 'card-dashed-body' : ''} text-center`}>
                    <div class="row align-items-center text-left mb-4">
                        <div class="col">
                            {!data.template ? <span class={`badge badge-soft-${active ? 'success' : 'danger'} p-2`}>{active ? 'Active' : 'Inactive'}</span> : 
                                <span class={`badge badge-soft-info p-2`}>Coming soon</span>
                            }
                        </div>
                        {!data.template && <div class='col-auto'>
                            <Dropdown 
					            size = 'sm' 
                                position = 'right' 
                                minWidth = {140}
                                popover = {<>
                                    <span class="dropdown-item" onClick={() => history.push(`/app/id-connect/edit/${data._id}`)}>Edit Campaign</span>
                                    <span class="dropdown-item" onClick={() => history.push(`/app/id-connect/${data._id}`)}>View submissions</span>
                                </>}
                            >
                                <a class="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle">
                                    <i class="tio-more-vertical"></i>
                                </a>
                            </Dropdown>
                        </div>}
                    </div>
                    <img src = {`/assets/svg/brands/${data.img}.svg`} class='avatar avatar-xl avatar-4x3 mb-3'/>
                    {/* <div class="avatar avatar-soft-primary avatar-xl avatar-circle mb-3">
                        <span class="avatar-initials">
                            <i class={data.icon}></i>
                        </span>
                    </div> */}
                    <div class="mb-2 link-unstyled ">
                        <h4 class='h3'>{data.title}</h4>
                        <p class='mb-1'>{data.description}</p>
                        {data.reward && 
                            <OverlayTrigger overlay = {<Tooltip>Reward Value</Tooltip>}>
                                <div class='badge badge-soft-primary mb-2'>{data.reward} XP</div>
                            </OverlayTrigger>
                        }
                        {data.discordCommand && 
                            <OverlayTrigger overlay = {<Tooltip>Discord Command Name</Tooltip>}>
                                <div class='badge badge-soft-primary mb-2 ml-2'>/{data.discordCommand}</div>
                            </OverlayTrigger>
                        }
                    </div>
                    <div class='mb-2'>
                        <Button 
                            text="Send Announcement"
                            size = "sm"
                            disabled={data.template || !active}
                            color = {active ? "primary" : "outline-primary"}
                            submitResponse = {() => setShowAnnouncement(true)}
                        />
                    </div>
                
            </div>
            <div class='card-footer'>
                <SwitchButton 
                    label = {
                        <div>Activate {loading && <span class='spinner-border-sm spinner-border ml-2'></span>}</div>
                    }
                    value = {active}
                    setValue = {(e) => {
                        if(!data.template) {
                            setActive(e);
                            toggleCampaignStatus(e)
                        }
                    }}
                />
            </div>
        </div>
        <ShowAnnouncement show = {showAnnouncement} setShow = {setShowAnnouncement} quest = {sampleQuest} data = {data}/>
        </>
    )
}









const comingSoon = [{
    status: {
        color: 'info',
        value: 'Coming soon'
    },
    icon: 'tio-twitter',
    title: 'Connect Twitter profiles of your users to get social context',
    description: 'Reward your discord members for linking their wallet addresses. This will help identify which discord members hold tokens.',
    reward: 10,
    template: true,
    img: 'twitter'
}]


const sampleQuest = {
    mission: 'Connect your wallet and get exclusive rewards.',
    guide: `1. Type the command /wallet-connect and press Enter
2. Click on the link to be redirected to the wallet connect page
3. Choose the wallet to connect . Sign in via your wallet
4. Your quest will be completed and XPs awarded.`,
    reward: {
        xpValue: '10'
    },
}