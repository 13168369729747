import { SimpleModal } from 'components/Models/Modal'
import Tags from 'components/Others/Tags'
import React from 'react'
import {Link} from 'react-router-dom'

const Templates = ({show, setShow, setShowUpload}) => {

    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
            // title = "Browse ideas"
            size = "xl"
        >   
            <div class='mb-4 d-flex align-items-center'>
                <div class='mr-3'>
                    <div class='avatar avatar-md avatar-circle avatar-secondary'>
                        <div class='avatar-initials'>
                            <i class='tio-document-text-outlined'></i>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 class='mb-1'>Browse Ideas</h3>
                    <div class=''>Start with these flows to increase revenue and engagement with little effort.</div>
                </div>
            </div>
            <div class=''>
                <div class='row'>
                    {templates.map(item => (
                        <div class='col-4'>
                            <Link to = "/app/journeys/new" class='card card-hover-shadow pointer mb-3'>
                                <div class='card-body'>
                                    <h5 class='small text-uppercase bold  text-muted h4 mb-2'>{item.category}</h5>
                                    <h5 class='card-title mb-2'>{item.name}</h5>
                                    <p class='card-text'>{item.description}</p>
                                    <div>
                                        <Tags tags = {item.tags}/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </SimpleModal>
    )
}

export default Templates



const templates = [{
    name: "Onboarding new customers",
    description: "Collect data from your new community members",
    category: 'Onboarding',
    tags: [{color: 'success', label: 'Convert User'}]
}, {
    name: "Onboarding new customers",
    description: "Collect data from your new community members",
    category: 'Onboarding',
    tags: [{color: 'success', label: 'Convert User'}]
}, {
    name: "Onboarding new customers",
    description: "Collect data from your new community members",
    category: 'Onboarding',
    tags: [{color: 'success', label: 'Convert User'}]
}, {
    name: "Onboarding new customers",
    description: "Collect data from your new community members",
    category: 'Onboarding',
    tags: [{color: 'success', label: 'Convert User'}]
}, {
    name: "Onboarding new customers",
    description: "Collect data from your new community members",
    category: 'Onboarding',
    tags: [{color: 'success', label: 'Convert User'}]
}, {
    name: "Onboarding new customers",
    description: "Collect data from your new community members",
    category: 'Onboarding',
    tags: [{color: 'success', label: 'Convert User'}]
}, ]