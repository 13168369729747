import React, {useState, useEffect, useContext} from 'react'
import {CheckBox} from 'components/Forms/Checkbox'
import {HrefButton, Button} from 'components/Forms/Buttons'
import {InputField, PasswordField} from 'components/Forms/TextInput'
import {Divider} from 'components/Others/Dividers'
import {Link} from 'react-router-dom'
import {loginSuccess} from 'store/actions/auth'
import {updateWorkplace} from 'store/actions/workplace'
import {createUser} from 'services/user/auth'
import {Store} from 'store'
import {splitName} from 'utilis/sanitisers'
import validation from 'utilis/validation'
import config from 'utilis/config'
import { ERROR_TYPES } from 'config/constants'
import toast from 'react-hot-toast'

const Form = (props) => {

    const {dispatch} = useContext(Store)
    const [values, setValues] = useState({name: '', email: '', password: '', password1: ''});
    const [errors, setErrors] = useState({name: false, email: false, password: false, password1: false})
    const [loading, setLoading] = useState(false);

    
    const submitResponse = async () => {
        try{
            if(!validateData()) return;
            setLoading(true);
            const {firstName, lastName} = splitName(values.name)

            const user = await createUser({
                firstName,
                lastName,
                email: values.email.trim().toLowerCase(),
                password: values.password,
            });
            loginSuccess(user, dispatch)
            updateWorkplace(user.workplaceId, dispatch)
            props.history.push('/app/create')
            setLoading(false);
        } catch(err){
            setLoading(false);
            if(err.response && err.response.data && err.response.data.error === ERROR_TYPES.USER_ALREADY_EXISTS){
                toast.error("This email address already exists, please try logging in. In case you forgot your password, you can find the reset the password link on login page.")
            }
            console.log(err);
        }
    }


    const validateData = () => {
        const new_errors = {name: false, email: false, password: false, password1: false};
        if(validation.isEmpty(values.name)) new_errors.name = true;
        if(!validation.isEmail(values.email)) new_errors.email = true;
        if(!validation.isPassword(values.password)) new_errors.password = true;
        if(values.password !==  values.password1) new_errors.password1 = true;
        setErrors(new_errors)
        if(Object.values(new_errors).includes(true)) return false;
        else return true;
    }


    const editValues = (name) => (value) => {
        setValues(prev => ({...prev, [name]: value }));
    };

    const editErrors = (name) => (value) => {
        setErrors(prev => ({...prev, [name]: value }));
    };

    useEffect(() => {
        setErrors({name: false, email: false, password: false, password1: false})
    }, [values])

    return(
        <form>
            <div class="text-center mb-5">
                <h1 class="display-4">Create your account</h1>
                <p>Already have an account? <Link to = {{pathname: "/", state: {...props.location.state}}}>Sign in here</Link></p>
            </div>
            <HrefButton 
                className = 'mb-4'
                text = "Sign up with Discord"
                img = "/assets/svg/brands/discord.svg"
                href = {`${config.serverLink}/api/v1/auth/discord`}
            />
            <Divider text = "OR"/>
            <InputField 
                name = "name"
                value = {values.name}
                error = {errors.name}
                setValue = {editValues('name')}
                setErrors = {editErrors('name')}
                placeholder = "Abhishek Aggarwal"
                label = "Name"
                errorText = "Please enter a valid name address"
                size = "lg"
            />
            <InputField 
                name = "email"
                value = {values.email}
                error = {errors.email}
                setValue = {editValues('email')}
                setErrors = {editErrors('email')}
                placeholder = "bzz@bee.com"
                label = "Email"
                errorText = "Please enter a valid email address"
                size = "lg"
            />
            <PasswordField 
                name = "password"
                value = {values.password}
                error = {errors.password}
                setValue = {editValues('password')}
                setErrors = {editErrors('password')}
                label = "Password"
                errorText = "Please enter a valid password"
                size = "lg"
            />
            <PasswordField 
                name = "password"
                value = {values.password1}
                error = {errors.password1}
                setValue = {editValues('password1')}
                setErrors = {editErrors('password1')}
                label = "Confirm Password"
                errorText = "Please enter a valid password"
                size = "lg"
            />
            <Button 
                text = "Create your account"
                loadingText = "Signing in..."
                submitResponse = {submitResponse}
                loading = {loading}
                size = "lg"
                block = {true}
                color = "primary"
            />
      </form>
    )
}

export default Form
