import React, {useState, useContext, useEffect} from 'react'
import { SimpleModal } from 'components/Models/Modal';
import { Store } from 'store'
import Select from 'components/Forms/Select2'
import { saveChannels } from 'store/actions/community'
import { Button } from 'components/Forms/Buttons';
import toast from 'react-hot-toast'
import { getAllChannelsApi } from 'services/activities';
import { InputField } from 'components/Forms/TextInput';
import {sendAnnouncementApi} from 'services/sources/'

const Announcement = ({show, setShow, quest, data}) => {


    const {state, dispatch} = useContext(Store);
    const [channels, setChannels] = useState([]);
    const [message, setMessage] = useState({
        title: quest.mission,
        description: quest.guide,
    })
    const [selectedChannel, setSelectedChannel] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if(data.flowType === 'FROM_WEBSITE_WITH_BUTTON'){
            setMessage({
                title: 'Verify your assets',
                description: 'This is a read-only connection. Do not share your private keys. We will never ask for your seed phrase. We will never DM you.',
            })
        } else {
            setMessage({
                title: quest.mission,
                description: quest.guide,
            })
        }
    }, [quest])
    
    
    useEffect(() => {
        setError(false)
    }, [selectedChannel])


    useEffect(() => {
        if(state.community.channels){
            formatChannels(state.community.channels)
        } else getAllChannels();
    }, [state.community.channels])

    const formatChannels = async (data) => {
        try{
            
            setChannels(data.map(item => ({
                value: item.channelId,
                label: item.name,
            })))

        } catch(err){
            console.log(err)
        }
    }
    
    const getAllChannels = async () => {
        try{
            const allChannels_ = await getAllChannelsApi();
            saveChannels(allChannels_, dispatch) 
        } catch(err){
            console.log(err)
        }
    }
    

    const sendAnnouncement = async () => {
        try{
            if(!selectedChannel){
                setError(true)
                return;
            }
            setLoading(true);
            let flowType = data.flowType
            console.log(flowType)
            console.log(quest.reward.xpValue)
            await sendAnnouncementApi(message, selectedChannel, quest.reward.xpValue, flowType)
            setLoading(false);
            setShow(false);
            
        } catch(err){
            console.log(err)
            setLoading(false);
        }
    }



    return (
        <SimpleModal 
            show = {show} 
            setShow = {setShow}
            title = "Send a message to all members"
            size = "md"
            footer={<div>
                <Button 
                    size="sm"
                    text=  "Send announcement"
                    loading={loading}
                    submitResponse = {() => sendAnnouncement()}
                />
            </div>}
        >
            <div>
                <Select 
                    options = {channels}
                    value = {selectedChannel}
                    setValue = {(value) => setSelectedChannel(value)}
                >
                    {(selectedChannel && channels.length > 0) ? <div class='btn btn-block btn-sm btn-outline-dark-2'>
                        <span class='text-truncate' >{channels.find(item => item.value === selectedChannel)?.label}</span>
                    </div> : <div class='btn btn-block btn-sm btn-outline-dark-2'>
                        <i class='tio-add-circle'></i> Select a channel
                    </div>}
                </Select>

                <div class="mt-4">
                    <InputField 
                        label="Title"
                        value={message.title}
                        setValue={(value) => setMessage({...message, title: value})}
                        labelClass = 'bold'
                    />
                    <InputField 
                        label="Description"
                        value={message.description}
                        setValue={(value) => setMessage({...message, description: value})}
                        labelClass = 'bold'
                        type = 'textarea'
                    />
                </div>
                {error && <div class='d-block invalid-feedback'>Please select the channel you want to send message to</div>}
            </div>

        </SimpleModal>
    )
}

export default Announcement






const onlyUnique = (key) => {
    return (prev, next) => {
        return prev[key] === next[key] ? false : true;
    }
}

