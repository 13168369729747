import React from 'react'
import { Link } from 'react-router-dom'

const Table = () => {
    return (
        <div class="table-responsive">
            <table class="table table-borderless table-striped table-lg table-thead-bordered table-nowrap table-align-middle card-table">
                <thead class="thead-light">
                    <tr class=''>
                        <th class="col">Journey</th>
                        <th class="col-auto table-column-right-aligned">Members Engaged</th>
                        <th class="col-auto table-column-right-aligned">Conversion</th>
                        <th class="col-auto"></th>
                    </tr>
                </thead>
                <tbody>
                    {journeys.map(item => <tr>
                        <td>
                            <div class="d-flex">
                                <span class="avatar avatar-success avatar-circle avatar-xss mt-2">
                                    <span class="avatar-initials"></span>
                                </span>
                                <div class="ml-3">
                                    <Link to ="/app/journeys/reactivation" >
                                        <h5 class="mb-0">{item.name}</h5>
                                    </Link>
                                    <small>{item.description}</small>
                                </div>
                            </div>
                        </td>
                        <td class="col-auto table-column-right-aligned">{item.membersEngaged}</td>
                        <td class="col-auto table-column-right-aligned">{item.conversion}%</td>
                        <td class="col-auto table-column-right-aligned">
                            <div className='btn btn-sm btn-white'>Edit flow</div>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}

export default Table


const journeys = [
    {
        name: "Onboarding new customers",
        description: "Collect data from your new community members",
        membersEngaged: '4,462',
        conversion: 76
    },
    {
        name: "Reactivate your top members",
        description: "Nudge your inactive users towards positive action",
        membersEngaged: '2,242',
        conversion: 31
    },
    {
        name: "Appreciate your contributors",
        description: "Award POAPs & more to your loyalists",
        membersEngaged: '1,242',
        conversion: 34
    },
    {
        name: "Nudge staking behavior",
        description: "Send reminders to your token holders  to stake ",
        membersEngaged: '6,121',
        conversion: 19
    },
    {
        name: "HODLer role assignment",
        description: "Assign or De assign roles basis who holds or flip your NFTs",
        membersEngaged: '10,121',
        conversion: 10
    },
]