import Tooltip1 from 'components/Others/Tooltip'
import React from 'react'

const HealthAtGlance = ({}) => {
    return (
        <div class='card mb-5'>
            <div class='card-body'>
                <div class='row'>
                    <div class='col'>
                        <h3 class='mb-0'>⚡️ Smart contract analysis at a glance</h3>
                        {/* <p>See the most important metrics that define your users..</p> */}
                    </div>
                </div>
                <HighLevelStats stats = {data} />
            </div>
        </div>
    )
}

export default HealthAtGlance





const data = [{
    label: 'Total unique wallets',
    value: '2,240',
    growth: 12,
    numOfDays: '7-day change',
}, {
    label: 'Total value locked',
    value: '$89,506',
    growth: 120,
    numOfDays: '7-day change',
}, {
    label: 'Total gas fee incurred',
    value: '$23,684',
    growth: 5,
    numOfDays: '7-day change'
}, {
    label: 'Total smart contracts',
    value: '5',
    growth: 'Swap_v3',
    numOfDays: 'most used',
}]



const HighLevelStats = ({stats}) => {
    return (
        <div class = 'row mx-n4'>
            {stats.map(item => <div class='col-md-3'>
                {item.type === 'progress' ? <ProgressStat {...item}/> : <Stat {...item}/>}
            </div>)}
        </div>
    )
}




const Stat = ({label, growth, value, numOfDays, description, tooltip}) => {
    return (
        <div class = 'p-3 my-3 rounded card-hover-change-bg pointer'>
            <h6 class="text-muted">{label}
                {tooltip && <Tooltip1 tooltip = {tooltip} />}
            </h6>
            {value !== null && <>
                <div class="row align-items-center gx-2 mb-1">
                    <div class="col-6">
                        <span class="card-title h2">{value}</span>
                    </div>
                    <div class="col-6">
                    </div>
                </div>
                <>
                    {isFloat(growth) ? <span class={`badge badge-soft-${growth >= 0 ? 'success' : 'danger'}`}>
                        <i class={`tio-trending-${growth > 0 ? 'up' : 'down'}`}></i> {growth}%
                    </span> : <span class='badge badge-soft-primary mr-2'>{growth}</span>}
                    <span class="text-body font-size-sm ml-1">{numOfDays}</span>
                </>
                {description && <small class='text-body font-size-sm'>{description}</small>}
            </>}
            {value === null && <div class="text-muted pt-3 ">
                <div class='spinner-border'></div>
            </div>}
        </div>
    )
}




const ProgressStat = ({totalPositive, totalNegative}) => {
    return (
        <div class = 'p-3 my-3 rounded card-hover-change-bg pointer'>
            <h6 class="text-muted">Sentiment analysis</h6>
            {(totalPositive !== null && totalNegative !== null) &&  <Progress 
                totalPositive = {totalPositive}
                totalNegative = {totalNegative}
            />}
            {(totalPositive === null && totalNegative === null) && <div class="text-muted pt-3 ">
                <div class='spinner-border'></div>
            </div>}
        </div>
    )
}


const Progress = ({totalPositive, totalNegative}) => {

    const data1 = parseInt(totalPositive/(totalPositive+totalNegative)*100)
    const data2 = parseInt(totalNegative/(totalPositive+totalNegative)*100)

    return (
        <div>
            {(totalPositive + totalNegative !== 0)  ? <div class="progress bg-white border  mb-2" style = {{height: '2rem'}}>
                <div class="progress-bar bg-soft-success text-success bold"  style={{width: data1+'%'}}>{data1}%</div>
                <div class="progress-bar bg-soft-danger text-danger bold"  style={{width: data2+"%"}}>{ data2}%</div>
            </div> : <div class="progress bg-white border  mb-2" style = {{height: '2rem'}}>
                <div class="progress-bar bg-soft-success text-success bold"  style={{width: '100%'}}>Not enough data</div>
                <div class="progress-bar bg-soft-danger text-danger bold"  style={{width: "0%"}}></div>
            </div>}
            <div class="d-flex justify-content-between small bold">
                <span>Positive</span>
                <span>Negative</span>
            </div>
        </div>
    )
}


function isFloat(value){
    if(!isFinite(value)) return false;
    if(typeof value === 'number') return true
    return typeof value === 'number' || isFinite(value) || Math.floor(value) === value;

    if(typeof n === 'number') return true
    return Number(n) === n && n % 1 !== 0;
}