import Avatar from 'components/Avatar'
import { PageHeader } from 'components/Text'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {getSegmentsApi, deleteSegmentApi} from 'services/workplace/segments'
import moment from 'moment'
import Dropdown from 'components/Forms/Dropdown'
import { SearchHeader } from 'components/Table'


const Segments = () => {

	const [searchInput, setSearchInput] = useState('')
	const [fetchedSegments, setFetchedSegments] = useState([])
	const [segments, setSegments] = useState([]);


	useEffect(() => {
		fetchSegments()
	}, [])


	const deleteSegment = async (allSegments, id) => {
		try{
			await deleteSegmentApi(id)
			const newSegments = [...allSegments]
			const index = newSegments.findIndex(segment => segment._id === id)
			newSegments.splice(index, 1)
			setFetchedSegments(newSegments)
			formatSegments(newSegments)
		} catch(err){
			console.log(err)
		}
	}

	const fetchSegments = async () => {
		try{

			const segments_ = await getSegmentsApi();
			console.log(segments_)
			setFetchedSegments(segments_)
			formatSegments(segments_)

		} catch(err){
			console.log(err)
		}
	}

	const formatSegments = (segments_) => {
		setSegments(segments_.map(item => ({
			...item,
			createdAt: moment(item.createdAt).format('Do MMM YY'),
			name: {
				label: <Link class='h5 mb-1' to = {`/app/segments/${item._id}`} >{item.name}</Link>,
				value: item.name,
			}, 
			createdBy: {
				value: item.createdBy.firstName,
				label: <Avatar 
					name = {item.createdBy.firstName + ' ' + item.createdBy.lastName}
					avatar = {item.createdBy.avatar}
				/>
			},
			totalMembers: Math.floor(Math.random() * 10000),
			options: {
				value: item._id,
				label: <Dropdown 
					size = 'sm' 
					position = 'top' 
					minWidth = {140}
					popover = {<>
						<span class="dropdown-item text-danger" onClick={() => deleteSegment(segments_, item._id)}>Delete Segment</span>
					</>}
				>
					<a class="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle">
						<i class="tio-more-vertical"></i>
					</a>
				</Dropdown>
			}
		})))
	}

  	return (
		<div>
			<PageHeader 
				title = "Segments"
				description = "Group users with similar behavior into cohorts for collective action (roles, rewards, comms etc.)"
				button={<Link class='btn btn-primary' to = "/app/segments/add">Create a segment</Link>}
			/>
			<div class='card mt-4'>
				<SearchHeader 
                    searchInput = {searchInput} 
                    setSearchInput = {setSearchInput}
                    searchPlaceholder  = "Search segments"
                />
				<Table 
					data = {segments}
					headers = {headers} 
				/>
			</div>
		</div>
  	)
}

export default Segments




const Table = ({data}) => {
    return (
        <div class="table-responsive">
            <table class="table table-borderless table-striped table-lg table-thead-bordered table-nowrap table-align-middle card-table">
                <thead class="thead-light">
                    <tr class=''>
                        <th class="col">Name</th>
                        <th class="col-auto table-column-right-aligned">Total Memebrs</th>
                        <th class="col-auto">Last Updated</th>
                        <th class="col-auto"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => <tr>
                        <td>
							{item.name.label}
							<p class='mb-0'>{item.description}</p>
                        </td>
                        <td class="col-auto table-column-right-aligned">{item.totalMembers}</td>
                        <td class="col-auto table-column-right-aligned">{item.createdAt}</td>
                        <td class="col-auto table-column-right-aligned">
                            {item.options.label}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}




const headers = [{
	value: 'name',
	label: 'Name',
}, {
	value: 'description',
	label: 'Description',
}, {
	value: 'createdBy',
	label: 'Created By',
}, {
	value: 'createdAt',
	label: 'Created On',
}, {
	value: 'options',
	label: '',
}]



