import { PageHeader } from 'components/Text'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'


const AddCampaign = () => {
  return (
    <div>
        <div class='row justify-content-center mt-5'>
            <div class='col-10'>
                <PageHeader 
                    title = "Create a new campaign"
                    description="Choose from the channel below to reward your community members with tokens."
                />
                <h3 class='mb-3'>On Chain Campaigns</h3>
                <div class='row'>
                    {templates.filter(item => item.category === 'on-chain').map(item => <CampaignType {...item}/>)}
                </div>
                <h3 class='mb-3 mt-5'>Off Chain Campaigns</h3>
                <div class='row'>
                    {templates.filter(item => item.category === 'off-chain').map(item => <CampaignType {...item}/>)}
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddCampaign



const CampaignType = ({name, description, link, icon}) => {

    const [isHover, setIsHover] = useState(false)

    const handleMouseEnter = () => {
        setIsHover(true)
    }
    const handleMouseLeave = () => {
        setIsHover(false)
    }

    return (
        <div class='col-4 mb-4'>
            <div class='card  card-hover-shadow card-hover h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div class='card-body  '>
                    {/* <img src = "/assets/svg/brands/eth.svg" class='img-fluid' style = {{height: '40px'}}/> */}
                    {/* {svg ? svg : <SlackSvg />} */}
                    <div class='avatar avatar-light avatar-circle avatar-sm'>
                        <div class='avatar-initials'>
                            <i class={icon}></i>
                        </div>
                    </div>
                    <p className='mt-2 bold text-dark  mb-0'>{name}</p>
                    <div className="mt-1 mb-0 small">Follow activities like transactions, holding time from your token smart contract</div>
                    <Link to = {link} className={`btn btn-xs mt-3 btn-pill btn-white card-hover-btn-dark  mt-1 mb-0`}>{!isHover ? <>Create</> : <>Create <i class='tio-arrow-forward ml-1'></i></>}</Link>
                </div>
            </div> 
        </div>
    )
}



const templates = [{
    name: 'Send tokens',
    description: 'Send tokens to your community members',
    icon: 'tio-coin',
    category: 'on-chain',
    link: '/app/campaigns/add/airdrop'
}, {
    name: 'Send NFTs',
    description: '',
    icon: 'tio-gradient',
    category: 'on-chain',
    link: '/app/campaigns/add/airdrop'
},{
    name: 'Send Soul bound tokens',
    description: '',
    icon: 'tio-medal',
    category: 'on-chain',
    link: '/app/campaigns/add/soul-bound'
},{
    name: 'Send Email',
    description: '',
    icon: 'tio-email',
    category: 'off-chain',
    link: '/app/campaigns/add/email'
},{
    name: 'Send Discord Message',
    description: '',
    icon: 'tio-message',
    category: 'off-chain',
    link: '/app/campaigns/add/discord-message'
},]