import { SearchHeader } from 'components/Table'
import { PageHeader } from 'components/Text'
import React from 'react'
import {Link} from 'react-router-dom'
import Table from './components/Table'
import Templates from './components/Templates'


const Campaigns = () => {

    const [searchInput, setSearchInput] = React.useState('')


    return (
        <div>
            <PageHeader 
				title="Campaigns" 
                description = "Run campaigns to engage your members & drive favorable project behavior "
				button = {<Link to ="/app/campaigns/add" class='btn btn-primary'>Create a new campaign</Link>}
			/>
            <div class='card'>
                <SearchHeader 
                    searchInput = {searchInput} 
                    setSearchInput = {setSearchInput}
                    searchPlaceholder  = "Search campaigns"
                />
                <Table />
            </div>
        </div>
  )
}

export default Campaigns