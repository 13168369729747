import moment from "moment"


const data = [
    {
        "label": "Source",
        "value": "source",
        "enums": [],
        "icon": "company",
        "default": true,
        "isMulti": true
    }, 
    {
        "label": "Smart Contract",
        "value": "Smart Contract",
        "enums": [{
            "value": "0",
            "label": "Lend_v2.0"
        }, {
            "value": "1",
            "label": "Stake_v5.0"
        }, {
            "value": "2",
            "label": "Swap_v3.0"
        }, {
            "value": "3",
            "label": "Stop_v1.0"
        }],
        "icon": "document-text",
        "default": true,
        "isMulti": true
        
    },
    {
        "label": "Event date",
        "value": "time",
        "icon": "calendar",
        "type": "DATE",
        "default": true,
        "defaultValues": {
            "startDate": new Date(moment().startOf('week')),
            "endDate": new Date()
        }
    },
    
]

export const initialFilters = data.filter(item => item.default).map(item => ({
    value: item.value, 
    filter: item.defaultValues ? item.defaultValues : (item.type === 'DATE' ? dateFilterNull : item.isMulti ? [] : '')
}))

export default data